import { ReactElement, useEffect, useState } from "react";
import useBookingStore from "./store/bookings";
import {
  Alert,
  Badge,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import BookingDetailsRender from "./components/BookingDetailsRender";
import EditDialog from "./components/Dialogs/EditDialog";
import ManualPurchaseDialog from "./components/Dialogs/ManualPurchaseDialog";
import RefundDialog from "./components/Dialogs/RefundDialog";
import CustomerDetailsRender from "./components/CustomerDetailsRender";
import EditCustomerDialog from "./components/Dialogs/EditCustomerDialog";
import TicketsDetailsRender from "./components/TicketsDetailsRender";
import RevisedDialog from "./components/Dialogs/revisedDialog";
import useModalsStore from "./store/modals";
import {
  AttachFile,
  Clear,
  ConfirmationNumberOutlined,
  ReceiptOutlined,
  Reply,
} from "@mui/icons-material";
import UploadFileDialog from "./components/Dialogs/UploadFileDialog";
import { RefundFileDialog } from "./components/Dialogs/RemoveFileDialog";
import { usePostComment } from "./hooks/usePostComment";
import { FormPostComment } from "./components/FormPostComment";
import ContactCustomerDialog from "./components/Dialogs/ContactCustomerdialog";
import { CheckboxContactCustomer } from "./components/CheckboxContactCustomer";
import { hasBeenContacted } from "./utils/bookingRow";
import { Logs } from "./_config/customerService.interface";
import { LoadingCircle } from "./components/LoadingCircle";

enum StatusColor {
  "Processing" = "warning",
  "Error" = "error",
  "Success" = "success",
}

const BookingDetails = () => {
  // tab value
  const [render, setRender] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [logs, setLogs] = useState<Logs[]>();
  const [fileManagement, setFileManagement] = useState<any>(null);
  const [fileType, setFileType] = useState<string>("");
  const {
    selectedBooking,
    setSelectedBooking,
    setOpenEditDialog,
    setOpenManualPurchaseDialog,
    setOpenRefundDialog,
    snackBar,
    setSnackBar,
    purchaseProvider,
    sendTickets,
    downloadTickets,
    openEditDialog,
    openManualPurchaseDialog,
    getFileManagement,
    setOpenUploadFileDialog,
    setFileTypeToUpload,
    downloadBill,
    openRemoveFileDialog,
    setOpenRemoveFileDialog,
    comment,
    setComment,
    retryFiles
  } = useBookingStore((state) => ({
    selectedBooking: state.selectedBooking,
    setSelectedBooking: state.setSelectedBookingByRequest,
    setOpenEditDialog: state.setOpenEditDialog,
    setOpenManualPurchaseDialog: state.setOpenManualPurchaseDialog,
    setOpenRefundDialog: state.setOpenRefundDialog,
    snackBar: state.snackBar,
    setSnackBar: state.setSnackBar,
    purchaseProvider: state.purchaseProvider,
    sendTickets: state.sendTickets,
    downloadTickets: state.downloadTickets,
    openEditDialog: state.openEditDialog,
    openManualPurchaseDialog: state.openManualPurchaseDialog,
    getFileManagement: state.getFileManagement,
    setOpenUploadFileDialog: state.setOpenUploadFileDialog,
    setFileTypeToUpload: state.setFileTypeToUpload,
    downloadBill: state.downloadBill,
    openRemoveFileDialog: state.openRemoveFileDialog,
    setOpenRemoveFileDialog: state.setOpenRemoveFileDialog,
    comment: state.comment,
    setComment: state.setComment,
    retryFiles: state.retryFiles,
  }));

  const { setOpenRevisedDialog } = useModalsStore((state) => ({
    setOpenRevisedDialog: state.setOpenRevisedDialog,
  }));

  const getRenderData = async () => {
    const url = window.location.pathname;
    const id = url.substring(url.lastIndexOf("/") + 1);
    await setSelectedBooking(id);
    setRender(true);
  };
  useEffect(() => {
    getRenderData();
    //console.log("BookingDetails useEffect");
  }, []);

  // actulizar los rows cuando cambie el selectedBooking
  useEffect(() => {
    //console.log("BookingDetails useEffect selectedBooking");
    //console.log("purchaseProvider", purchaseProvider);
    if (render) setLogs(purchaseProvider.logs);
  }, [purchaseProvider]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const renderDate = (date: string) => {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  };

  const handleDownloadTickets = async () => {
    console.log("handleDownloadTickets");
    const done = await downloadTickets([selectedBooking.id]);
    console.log("done", done);
    if (!done) {
      setSnackBar({
        open: true,
        message: "Error al descargar los tickets",
        severity: "error",
      });
      setTimeout(() => {
        setSnackBar({
          open: false,
          message: "Error al descargar los tickets",
          severity: "error",
        });
      }, 2000);
    }
  };

  const handleSendTickets = async () => {
    const done = await sendTickets("bookingDetails");

    if (done) {
      setSnackBar({
        open: true,
        message: "Send Tickets successfully",
        type: "success",
      });

      setTimeout(() => {
        setSnackBar({
          open: false,
          message: "Send Tickets successfully",
          type: "success",
        });
      }, 2000);
    } else {
      setSnackBar({
        open: true,
        message: "Error send Tickets",
        severity: "error",
      });

      setTimeout(() => {
        setSnackBar({
          open: false,
          message: "Error send Tickets",
          severity: "error",
        });
      }, 2000);
    }
  };

  const isPending = () => {
    const booking = selectedBooking;
    const isPending =
      booking.status === "Processing" &&
        ["Error", "Processing"].includes(booking.booking_handler.status)
        ? true
        : false;
    return isPending;
  };

  const handleRevised = async () => {
    setOpenRevisedDialog(true);
  };
  const handleRetryFiles = async () => {
    const isDoneRetry = await retryFiles(selectedBooking.booking_handler.purchases_providers[0].id);
    if (isDoneRetry) {
      setSnackBar({
        open: true,
        message: "Retry Files successfully",
        type: "success",
      });

      setTimeout(() => {
        setSnackBar({
          open: false,
          message: "Retry Files successfully",
          type: "success",
        });
      }, 2000);
    } else {
      setSnackBar({
        open: true,
        message: "Error Retry Files",
        severity: "error",
      });

      setTimeout(() => {
        setSnackBar({
          open: false,
          message: "Error Retry Files",
          severity: "error",
        });
      }, 2000);
    }
  };

  const canRetryFiles = () => {
    const bookingVenue = selectedBooking.cart.items[0].venue;
    const purchaseProvider = selectedBooking.booking_handler.purchases_providers[0];
    const arrRetryBotVenues = ["PAR - VEPA"];
    return arrRetryBotVenues.includes(bookingVenue.name) && purchaseProvider.status === "Success" ? true : false;
  };

  const ActionsRender = () => {
    return (
      <>
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            //navigate(-1)
            setRender(false);
            setSelectedBooking(null);
            window.history.back();
          }}
        >
          Return
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpenEditDialog(true)}
        >
          Edit booking
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpenManualPurchaseDialog(true)}
          disabled={selectedBooking.booking_handler === null}
        >
          Manunal purchase
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpenRefundDialog(true)}
        >
          Refund
        </Button>
        <Button variant="outlined" size="small" onClick={handleSendTickets}>
          Send Tickets
        </Button>
        <Button variant="outlined" size="small" onClick={handleDownloadTickets}>
          Download Tickets
        </Button>
        {isPending() && (
          <Button variant="outlined" size="small" onClick={handleRevised}>
            Revised
          </Button>
        )}
        {isPending() && canRetryFiles() && (
          <Button variant="outlined" size="small" onClick={handleRetryFiles}>
            Retry Files
          </Button>
        )}
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center", border: "1px solid rgba(25, 118, 210, 0.5)", color: "#1976d2", padding: "3px 9px", borderRadius: "4px" }}>
          <Typography sx={{
            fontFamily: "Roboto",
            fontWeight: 500,
            fontSize: "0.8125rem",
            lineHeight: "1.75",
            letterSpacing: "0.02857em",
          }}>CONTACT</Typography>
          <CheckboxContactCustomer id={selectedBooking.id} value={hasBeenContacted(logs)} />
        </Box>
      </>
    );
  };

  useEffect(() => {
    console.log("selectedBooking", selectedBooking);
    if (!selectedBooking.booking_handler?.purchases_providers[0]?.id) return;
    const fetchFileManagement = async () => {
      const fileManagement = await getFileManagement(
        selectedBooking.booking_handler?.purchases_providers[0]?.id
      );
      //console.log("fileManagement", fileManagement);
      setFileManagement(fileManagement);
    };
    fetchFileManagement();
  }, [selectedBooking]);

  const handleClickAttachTickets = () => {
    console.log("UploadFileIcon Tickets");
    setOpenUploadFileDialog(true);
    setFileTypeToUpload("tickets");
    setSelectedBooking(selectedBooking.id);
  };

  const handleClickAttachBills = () => {
    console.log("UploadFileIcon Bills");
    setOpenUploadFileDialog(true);
    setFileTypeToUpload("bills");
    //setSelectedBooking(selectedBooking.id);
  };

  const handleDownloadBill = async () => {
    console.log("handleDownloadBill");
    const done = await downloadBill([selectedBooking.id]);
    if (!done) {
      setSnackBar({
        open: true,
        message: "Error downloading Bills",
        severity: "error",
      });
      setTimeout(() => {
        setSnackBar({
          open: false,
          message: "Error downloading Bills",
          severity: "error",
        });
      }, 2000);
    }
  };

  const handleClickAttachInvoice = () => {
    console.log("UploadFileIcon Invoice");
    setOpenUploadFileDialog(true);
    setFileTypeToUpload("invoice");
    //setSelectedBooking(selectedBooking.id);
  };

  const handleDownloadInvoice = async () => {
    // TODO
  };

  const handleClickRemoveTicket = () => {
    console.log("handleClickRemoveTicket");
    setFileType("tickets");
    setOpenRemoveFileDialog(true);
  };

  const handleClickRemoveBill = () => {
    console.log("handleClickRemoveBill");
    setFileType("bills");
    setOpenRemoveFileDialog(true);
  };

  const FilesRender = () => {
    return (
      <Grid marginTop={"2rem"}>
        <Typography variant="h6">Files</Typography>
        <Grid marginTop={"1rem"} container>
          <Grid item xs={12} gap={2} display={"flex"}>
            {fileManagement?.has_tickets ? (
              <Box sx={{ position: "relative" }}>
                <Badge badgeContent={<Clear fontSize="inherit" />} sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  ":hover": {
                    cursor: "pointer"
                  }
                }} color="error" onClick={handleClickRemoveTicket}>
                </Badge>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleDownloadTickets}
                  startIcon={<ConfirmationNumberOutlined />}
                >
                  tickets
                </Button>
              </Box>
            ) : (
              <Button
                variant="outlined"
                size="small"
                onClick={handleClickAttachTickets}
                startIcon={<AttachFile />}
              >
                attach tickets
              </Button>
            )}
            {fileManagement?.has_bill ? (
              <Box sx={{ position: "relative" }}>
                <Badge badgeContent={<Clear fontSize="inherit" />} sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  ":hover": {
                    cursor: "pointer"
                  }
                }} color="error" onClick={handleClickRemoveBill}>
                </Badge>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleDownloadBill}
                  startIcon={<ReceiptOutlined />}
                >
                  bill
                </Button>
              </Box>
            ) : (
              <Button
                variant="outlined"
                size="small"
                onClick={handleClickAttachBills}
                startIcon={<AttachFile />}
              >
                attach bill
              </Button>
            )}
            {fileManagement?.has_invoice ? (
              <Button
                variant="outlined"
                size="small"
                onClick={handleDownloadInvoice}
                startIcon={<ReceiptOutlined />}
                disabled
              >
                invoice
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                onClick={handleClickAttachInvoice}
                startIcon={<AttachFile />}
                disabled
              >
                attach invoice
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return render ? (
    <>
      <Grid padding={"2rem"} container>
        <Grid item xs={12} display={"flex"} gap={"1rem"}>
          <ActionsRender />
        </Grid>
        <Grid item xs={12} display={"flex"} gap={"1rem"} marginTop={"1rem"}>
          <Grid item xs={6} sx={{
            maxWidth: 650,
          }} gap={"1rem"} marginTop={"1rem"}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Booking" {...a11yProps(0)} />
                <Tab label="Customer" {...a11yProps(1)} />
                <Tab label="Ticket" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <>
                <BookingDetailsRender />
                <TicketsDetailsRender fileManagement={fileManagement} />
                <FilesRender />
              </>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <CustomerDetailsRender />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <TicketsDetailsRender fileManagement={fileManagement} />
            </CustomTabPanel>
          </Grid>
          <Grid item display={"flex"} flexDirection={"column"} xs={6} gap={"1rem"} marginTop={"1rem"} sx={{ width: "100px" }}>
            <TableContainer title="Logs" component={Paper}>
              <Table
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs
                    ?.sort((prev, next) => {
                      // created_at
                      const iPrev = prev.created_at;
                      const iNext = next.created_at;
                      if (iPrev < iNext) return -1;
                      if (iPrev > iNext) return 1;
                      return 0;
                    })
                    .map((row: any) => (
                      <TableRow
                        key={row.id}                       
                        sx={{ backgroundColor: row.type === "Contacted" ? "lightgreen" : row.type === "Comment" ? "lightblue" : "white" }}
                      >
                        <TableCell>{renderDate(row.created_at)}</TableCell>
                        <TableCell>
                          <Chip
                            label={row.status}
                            color={
                              StatusColor[
                              row.status as keyof typeof StatusColor
                              ]
                            }
                            variant="outlined"
                            size="small"
                          />
                        </TableCell>
                        <TableCell>{row.message}</TableCell>
                        <TableCell>{row.by}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <FormPostComment />
          </Grid>
        </Grid>
      </Grid>

      {openEditDialog && <EditDialog />}
      <UploadFileDialog />
      {openManualPurchaseDialog && <ManualPurchaseDialog />}
      <RefundDialog />
      <EditCustomerDialog />
      <RefundFileDialog fileType={fileType} fileManagement={fileManagement} setFileManagement={setFileManagement} />
      <RevisedDialog />
      <ContactCustomerDialog />
      <Snackbar open={snackBar.open} autoHideDuration={2000}>
        <Alert severity={snackBar.severity} sx={{ width: "100%" }}>
          {snackBar.message}
        </Alert>
      </Snackbar>
    </>
  ) : (
    <>
      <LoadingCircle />
    </>
  );
};

export default BookingDetails;
