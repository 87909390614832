import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface Props {
  data: any;
}

export const OneLineChart = (props: Props) => {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          type="number"
          domain={([dataMin, dataMax]) => {
            return [+dataMin.toFixed(2), +dataMax.toFixed(2)];
          }}
        />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="value" fill="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};
