import { Reply } from "@mui/icons-material";
import { Alert, Box, Button, FormControl, TextField } from "@mui/material";
import { usePostComment } from "../hooks/usePostComment";
import { useState } from "react";
import { LoadingCircle } from "./LoadingCircle";

export function FormPostComment() {
  const { loading, postComment, errorMsg } = usePostComment();
  const [comment, setComment] = useState("");

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComment(event.target.value);
  }

  const handleSubmitComments = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    console.log("comment: ", comment);
    await postComment({
      comment,
      type: "Comment",      
    });
    setComment("");
  };

  return (
    <FormControl component="form" onSubmit={handleSubmitComments} fullWidth>
      <Box display={"flex"} gap={"1rem"} marginBottom={".5rem"}>
        <TextField
          value={comment}
          onChange={handleCommentChange}
          id="comments"
          size="small"
          variant="outlined"
          fullWidth
          placeholder="Add a comment..."
        />
        {comment.length > 0 && (
          <Button type="submit" variant="contained" size="small" disabled={loading}>
            {loading ? <LoadingCircle /> : <Reply />}
          </Button>
        )}
      </Box>
      {errorMsg && (
        <Alert severity="error" >
          {errorMsg}
        </Alert>
      )}
    </FormControl>
  );
}