import create from "zustand";

interface State {
  openRevisedDialog: boolean;
  setOpenRevisedDialog: (openRevisedDialog: boolean) => void;
  openContactCustomerDialog: boolean;
  setOpenContactCustomerDialog: (openContactCustomerDialog: boolean) => void;
}

const useModalsStore = create<State>((set) => ({
  openRevisedDialog: false,
  setOpenRevisedDialog: (openRevisedDialog) => set({ openRevisedDialog }),
  openContactCustomerDialog: false,
  setOpenContactCustomerDialog: (openContactCustomerDialog) => set({ openContactCustomerDialog }),
}));

export default useModalsStore;