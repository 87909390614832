import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import Filters from "../../components/Filters";
import useOrdersStore from "../../hooks/useOrders";

const data = [
  {
    name: "Sagrada",
    alerts: [
      {
        name: "3 attempts",
        criteria: ["on hold", "3 attempts"],
        record: [""],
      },
      {
        name: "To review",
        criteria: ["to review", "order"],
        record: [""],
      },
    ],
  },
  {
    name: "Vaticano",
    alerts: [
      {
        name: "3 attempts",
        criteria: ["on hold", "3 attempts"],
        record: [""],
      },
      {
        name: "To review",
        criteria: ["to review", "order"],
        record: [""],
      },
    ],
  },
];

const renderProjects = () => {
  return data.map((el, i) => {
    return (
      <Grid container item xs={12} key={i}>
        <Grid item xs={12}>
          <Typography variant="h6">{el.name}</Typography>
        </Grid>
        <Grid container item xs={12} sx={{ gap: "10px" }}>
          {el.alerts.map((alert, j) => {
            return (
              <Grid sx={{ mt: 1, mb: 1 }} key={j}>
                <Paper sx={{ p: 1 }} elevation={1}>
                  <Grid>
                    <h4>{alert.name}</h4>
                  </Grid>
                  <Divider sx={{ mt: 0.5, mb: 0.5 }} />
                  <Grid>x times</Grid>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  });
};

const Alerts = () => {
  const { getAlerts, projects } = useOrdersStore((state) => ({
    getAlerts: state.getAlerts,
    projects: state.projects,
  }));

  useEffect(() => {
    getAlerts();
  }, []);

  const render2 = () => {
    return projects.map((el, i) => {
      return (
        <Grid container item xs={12} key={i}>
          <Grid item xs={12}>
            <Typography variant="h6">{el.name}</Typography>
          </Grid>
          <Grid container item xs={12}>
            {el.alerts.map((alert, j) => (
              <Grid item xs={6} sx={{ p: 1 }} key={j} >
                <Paper sx={{ p: 1 }} elevation={1}>
                  <Grid>
                    <h4>{alert.name}</h4>
                  </Grid>
                  <Divider sx={{ mt: 0.5, mb: 0.5 }} />
                  <TableContainer component={Grid}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head">Criteria</TableCell>
                          <TableCell>{alert.criteria.join(" / ")}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Counter</TableCell>
                          <TableCell>{alert.record.length}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid container sx={{ p: 2 }}>
      <Grid container item xs={12}>
        <Filters project="alerts" enviroment="alerts" />
      </Grid>
      {/* <Grid>{renderProjects()}</Grid> */}
      <Grid container>{render2()}</Grid>
    </Grid>
  );
};

export default Alerts;
