import { Box, Button, Grid, TextField } from "@mui/material";
import useHelpersStore from "../../../hooks/useHelpers";
import { ICustomer } from "../../../interfaces/booking.interface";
import useBookingStore from "../store/bookings";
import { useEffect, useState } from "react";
import Vars from "../_config/vars";

const CustomerDetailsRender = () => {

  const { selectedBooking, setSelectedBooking, setOpenEditCustomerDialog, bookingsPending, setBookingPendingRows, setSnackBar } = useBookingStore((state) => ({
    setOpenEditCustomerDialog: state.setOpenEditCustomerDialog,
    selectedBooking: state.selectedBooking,
    setSelectedBooking: state.setSelectedBooking,
    bookingsPending: state.bookingsPending,
    setBookingPendingRows: state.setBookingPendingRows,
    setSnackBar: state.setSnackBar,
  }));

  const { parseToSpaceCase, } = useHelpersStore((state) => ({
    formatUrl: state.formatUrl,
    renderValue: state.renderValue,
    parseToSpaceCase: state.camelCaseToSpaceCase,
  }));

  const { customer } = selectedBooking;

  const [edit, setEdit] = useState(false);

  const customerDetails: any = {
    //id: customer.id,
    firstName: customer.first_name,
    lastName: customer.last_name,
    email: customer.email ? customer.email : "-",
    phone: customer.phone ? customer.phone : "-",
    address: customer.address ? customer.address : "-",
    city: customer.city ? customer.city : "-",
    country: customer.country ? customer.country : "-",
    zipCode: customer.postal_code ? customer.postal_code : "-",
  };

  const openSnackHandler = (e: any) => {
    navigator.clipboard.writeText(e.target.value)
    setSnackBar({
      open: true,
      message: "Copied to clipboard",
      severity: "info",
    });

    setTimeout(() => {
      setSnackBar({
        open: false,
        message: "Copied to clipboard",
        severity: "info",
      });
    }, 2000);
  }

  const customerDetailsFields = Object.keys(customerDetails).sort((prev, next) => {
    const iPrev = prev;
    const iNext = next;
    if (iPrev < iNext) return -1;
    if (iPrev > iNext) return 1;
    return 0;
  }).map((key, i) => {
    return (
      <Box key={key}
        onClick={openSnackHandler}
      >
        <TextField
          label={parseToSpaceCase(key)}
          key={key}
          name={key}
          fullWidth
          value={customerDetails[key]}
          variant="standard"
          size="small"
          sx={{
            '& .MuiInputBase-root::before': {
              content: Vars.booking.editable.includes(key) && edit ? '""' : "none",
            },
            '& .MuiInputBase-root::after': {
              content: Vars.booking.editable.includes(key) && edit ? '""' : "none",
            },
            '& .MuiInput-input': {
              cursor: "pointer",
            },
            width: "70%",
            marginBottom: 2,
          }}
          InputProps={{
            readOnly: Vars.booking.editable.includes(key) && edit ? false : true,
          }}
        />
      </Box>
    );
  });

  const handleClick = () => {
    setOpenEditCustomerDialog(true);
  }

  return (
    <Grid container display={"flex"} flexDirection={"column"}>
      <Grid item sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
        {customerDetailsFields}
      </Grid>
      <Grid item marginTop={"2rem"}>
        <Button variant="outlined" onClick={handleClick}>
          Edit customer
        </Button>
      </Grid>
    </Grid>
  )
};

export default CustomerDetailsRender;