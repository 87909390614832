import * as XLSX from "xlsx";

export const reportToXlsx = async (orders: any[]) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(orders);
  XLSX.utils.book_append_sheet(wb, ws, "");
  let date = new Date();
  let nameFile = `report-${date.getDate()}_${
    date.getMonth() + 1
  }_${date.getFullYear()}.xlsx`;
  XLSX.writeFile(wb, nameFile);
};

interface IData {
  date: Date;
  data: {
    project: string;
    category: string;
    ticketsName: string;
    tickets: number;
    cost: number;
    fee: number;
    sales: number;
    refundCost: number;
    refundFee: number;
    refundSales: number;
    grossIncome: number;
    netIncome: number;
    iva: number;
  }[][];
}

interface IData2 {
  [x: string]: {
    [x: string]:
      | [
          {
            category: string;
            ticketsName: string;
            tickets: number;
            amountSupplied: number;
            revisedFee: number;
            revisedPaid: number;
            refundSupplied: number;
            refundFee: number;
            refundSales: number;
            grossIncome: number;
            netIncome: number;
            iva: number;
            comissions: number;
            ppc: number;
            netMargin: number;
          }
        ]
      | [];
  };
}

export class SheetsJS {
  private readonly data: IData2;
  constructor(data: any) {
    this.data = data;
  }

  test2() {
    const headers = [
      "Fecha",
      "Monumento",
      "Producto - Variant",
      "Tickets",
      "Amount supplied - Revised",
      "Fee - Revised",
      "Amount Paid - Revised",
      "Amount supplied - Refund",
      "Fee - Refund",
      "Amount paid - Refund",
      "Gross Income",
      "Net Income",
      "IVA",
    ];

    const workbook = XLSX.utils.book_new();
    workbook.SheetNames.push("Export");
    let ws_data = <any[]>[headers];

    var dataKeys = Object.keys(this.data);
    for (const dataKey of dataKeys) {
      const dataValue = this.data[dataKey];
      const projects = Object.keys(dataValue);
      projects.map((project, i) => {
        const projectData = dataValue[project];
        if (projectData.length === 0) return;
        for (const rowData of projectData) {
          const row = [
            new Date(dataKey).toLocaleDateString("es-ES"),
            project,
            rowData.category + " " + rowData.ticketsName,
            rowData.tickets,
            rowData.amountSupplied,
            rowData.revisedFee,
            rowData.revisedPaid,
            rowData.refundSupplied,
            rowData.refundFee,
            rowData.refundSales,
            rowData.grossIncome,
            rowData.netIncome,
            rowData.iva,
          ];
          ws_data.push(row);
        }
      });
    }
    var ws = XLSX.utils.aoa_to_sheet(ws_data);

    //* MERGES
    let m: { s: { r: number; c: number }; e: { r: number; c: number } }[] = [];
    const data_rows = ws_data.slice(1);
    const uqDays = Array.from(new Set(data_rows.map((row, i) => row[0])));
    console.log(data_rows);
    uqDays.map((day, i) => {
      const firstIndex = data_rows.findIndex((row) => row[0] === day) + 1;
      console.log(data_rows.slice(firstIndex - 1));
      const lastIndex = data_rows
        .slice(firstIndex - 1)
        .findIndex((row) => row[0] !== day);
      console.log("--------------------");
      console.log(day);
      console.log(firstIndex);
      console.log(lastIndex);
      m.push({
        s: { r: firstIndex, c: 0 },
        e: {
          r: lastIndex === -1 ? data_rows.length : firstIndex + lastIndex - 1,
          c: 0,
        },
      });
    });
    console.log(m);
    ws["!merges"] = m;

    // const uqProjects = Array.from(new Set(data_rows.map((row, i) => row[1])));
    // for (const project of uqProjects) {
    //   const firstIndex = data_rows.findIndex((row) => row[1] === project) + 1;
    //   const lastIndex = data_rows.findIndex((row) => row[1] !== project) + 1;
    //   m.push({
    //     s: { r: firstIndex, c: 1 },
    //     e: {
    //       r: lastIndex < firstIndex ? data_rows.length : lastIndex,
    //       c: 1,
    //     },
    //   });
    // }
    workbook.Sheets["Export"] = ws;
    XLSX.writeFile(workbook, "test.xlsx");
  }

  // test() {
  //   const headers = [
  //     "Fecha",
  //     "Monumento",
  //     "Producto - Variant",
  //     "Tickets",
  //     "Amount supplied - Revised",
  //     "Fee - Revised",
  //     "Amount Paid - Revised",
  //     "Amount supplied - Refund",
  //     "Fee - Refund",
  //     "Amount paid - Refund",
  //     "Gross Income",
  //     "Net Income",
  //     "IVA",
  //   ];
  //   const workbook = XLSX.utils.book_new();
  //   workbook.SheetNames.push("Export");

  //   var ws_data = <any[]>[headers];
  //   const merges = [];
  //   for (const day of this.data) {
  //     for (const projectData of day.data) {
  //       const projectArray: { [x: string]: any }[] = [];
  //       for (const rowData of projectData) {
  //         const tourIndex = projectArray.findIndex(
  //           (p) =>
  //             p.category.includes("SAGRADA FAMILIA TOUR") &&
  //             p.ticketsName === rowData.ticketsName
  //         );

  //         if (tourIndex !== -1) {
  //           console.log(projectArray[tourIndex].category);
  //           console.log(projectArray[tourIndex].ticketsName);
  //           console.log(projectArray[tourIndex].tickets);
  //           console.log(rowData.tickets);
  //           projectArray[tourIndex].tickets += rowData.tickets;
  //           projectArray[tourIndex].cost += rowData.cost;
  //           projectArray[tourIndex].fee += rowData.fee;
  //           projectArray[tourIndex].sales += rowData.sales;
  //           projectArray[tourIndex].refundCost += rowData.refundCost;
  //           projectArray[tourIndex].refundFee += rowData.refundFee;
  //           projectArray[tourIndex].refundSales += rowData.refundSales;
  //           projectArray[tourIndex].grossIncome += rowData.grossIncome;
  //           projectArray[tourIndex].netIncome += rowData.netIncome;
  //           projectArray[tourIndex].iva += rowData.iva;
  //         } else {
  //           projectArray.push(rowData);
  //         }
  //       }
  //       console.log("projectArray");
  //       console.log(projectArray);
  //       for (const rowData of projectArray) {
  //         const row = [
  //           new Date(day.date).toLocaleDateString("es-ES"),
  //           rowData.project,
  //           `${rowData.category} ${rowData.ticketsName}`,
  //           rowData.tickets,
  //           rowData.cost,
  //           rowData.fee,
  //           rowData.sales,
  //           rowData.refundCost,
  //           rowData.refundFee,
  //           rowData.refundSales,
  //           rowData.grossIncome,
  //           rowData.netIncome,
  //           rowData.iva,
  //         ];
  //         ws_data.push(row);
  //       }
  //     }
  //   }
  //   for (const day of this.data) {
  //     for (const projectData of day.data) {
  //       for (const rowData of projectData) {
  //         const row = [
  //           new Date(day.date).toLocaleDateString("es-ES"),
  //           rowData.project,
  //           `${rowData.category} ${rowData.ticketsName}`,
  //           rowData.tickets,
  //           rowData.cost,
  //           rowData.fee,
  //           rowData.sales,
  //           rowData.refundCost,
  //           rowData.refundFee,
  //           rowData.refundSales,
  //           rowData.grossIncome,
  //           rowData.netIncome,
  //           rowData.iva,
  //         ];
  //         // ws_data.push(row);
  //       }
  //       // const mergeProject = {
  //       //   s: [ws_data.length - projectData.length, 1],
  //       //   e: [ws_data.length - 1, 1],
  //       // };
  //       // merges.push(mergeProject);
  //     }
  //   }
  //   var ws = XLSX.utils.aoa_to_sheet(ws_data);
  // const m = merges.map((merge) => {
  //   return {
  //     s: { r: merge.s[0], c: merge.s[1] },
  //     e: { r: merge.e[0], c: merge.e[1] },
  //   };
  // });
  // ws["!merges"] = m;
  // const merge = [{ s: { r: 0, c: 0 }, e: { r: 3, c: 0 } }];
  // ws["!merges"] = merge;
  //   workbook.Sheets["Export"] = ws;
  //   XLSX.writeFile(workbook, "test.xlsx");
  // }

  _writeProductRow() {}
}
