import { useEffect, useState } from "react";
import useBookingStore from "../store/bookings";

type SnackbarState = {
    open: boolean;
    message: string;
    severity: "success" | "warning" | "error" | "info";
};

export function useSnackBar() {
    //const [snackBar, setSnackBar] = useState<SnackbarState>({ open: false, message: '', severity: 'info' });
    const { snackBar, setSnackBar } = useBookingStore((state) => ({
        snackBar: state.snackBar,
        setSnackBar: state.setSnackBar,
    }));

    const showSnackBar = (message: string, severity: "success" | "warning" | "error" | "info") => {
        console.log("Show snackbar");
        setSnackBar({ open: true, message, severity });

        setTimeout(() => {
            setSnackBar({ open: false, message, severity: "info" });
        }, 3000);
    };

    return { snackBar, showSnackBar };
}