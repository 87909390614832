import Tools from "../../../enum/tools";
import { IProject } from "../../../interfaces/IProject";

const ParkGuellVars = {
  project: "parkguell" as IProject,
  email: "albalopezgomez09245@gmail.com",
  // TODO: WIP
  fees: {
    "Parc Guell Tickets": {
      adult: 6,
      reduced: 6,
      free: 0,
    },
  },
  officialPrice: {
    "Parc Guell Tickets": {
      adult: 10,
      reduced: 7,
      free: 0,
    },
    "Parc Guell Tickets Guided EN": {
      adult: 22,
      reduced: 19,
      free: 0,
    },
    "Parc Guell Tickets Guided ES": {
      adult: 22,
      reduced: 19,
      free: 0,
    },
    "Parc Guell Tickets Guided FR": {
      adult: 22,
      reduced: 19,
      free: 0,
    },
    "Parc Guell Tickets Guided IT": {
      adult: 22,
      reduced: 19,
      free: 0,
    },
  },
  client: {
    editable: ["firstName", "lastName", "email"],
    sorted: ["firsttName", "lastName", "email", "phoneNumber", "country"],
  },
  order: {
    editable: [
      "status",
      "purchaseMail",
      "trackingNumber",
      "timeRange",
      "ticketsDate",
    ],
    sorted: [
      "status",
      "attempts",
      "trackingNumber",
      "purchaseMail",
      // "cardnumber",
      "wc_ref",
      "wc_id",
      "totalPrice",
      "createdAt",
      "saleId",
      // "qontoAmount",
      // "qontoIds",
      // "error",
      // "urlError",
      "timeRange",
      "ticketsDate",
    ],
  },
  status: {
    "payment incompleted": true,
    "to review": true,
    refund: true,
    "on hold": true,
    revised: true,
    "await mail": true,
    "await ok": true,
    "await bot": true,
    completed: true,
    "blocked ip": true,
    "high priority": true,
  },
  tools: [Tools.refunds, Tools.csv, Tools.refresh, Tools.tickets],
  hours: [
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
  ],
};

export default ParkGuellVars;
