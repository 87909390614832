import { useState } from "react";
import PersistentDrawerRight from "../AppBar/AppBar";

const Outer = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <PersistentDrawerRight open={open} setOpen={setOpen} />
    </>
  );
};

export default Outer;
