export const ordersByHourBase = [
  { name: "0", perc: 0 },
  { name: "1", perc: 0 },
  { name: "2", perc: 0 },
  { name: "3", perc: 0 },
  { name: "4", perc: 0 },
  { name: "5", perc: 0 },
  { name: "6", perc: 0 },
  { name: "7", perc: 0 },
  { name: "8", perc: 0 },
  { name: "9", perc: 0 },
  { name: "10", perc: 0 },
  { name: "11", perc: 0 },
  { name: "12", perc: 0 },
  { name: "13", perc: 0 },
  { name: "14", perc: 0 },
  { name: "15", perc: 0 },
  { name: "16", perc: 0 },
  { name: "17", perc: 0 },
  { name: "18", perc: 0 },
  { name: "19", perc: 0 },
  { name: "20", perc: 0 },
  { name: "21", perc: 0 },
  { name: "22", perc: 0 },
  { name: "23", perc: 0 },
];
