import create from "zustand";

interface HelpersStatus {
  camelCaseToSpaceCase: (text: string) => string;
  formatUrl: (url: string) => string;
  renderValue: (value: any) => any;
}

const useHelpersStore = create<HelpersStatus>((set) => {
  return {
    camelCaseToSpaceCase: (text) => {
      const result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },

    formatUrl: (url) => {
      // Split the URL into an array to distinguish double slashes from single slashes
      var doubleSlash = url.split("//");

      // Format the strings on either side of double slashes separately
      var formatted = doubleSlash
        .map(
          (str) =>
            // Insert a word break opportunity after a colon
            str
              .replace(/(?<after>:)/giu, "$1<wbr>")
              // Before a single slash, tilde, period, comma, hyphen, underline, question mark, number sign, or percent symbol
              .replace(/(?<before>[/~.,\-_?#%])/giu, "<wbr>$1")
              // Before and after an equals sign or ampersand
              .replace(/(?<beforeAndAfter>[=&])/giu, "<wbr>$1<wbr>")
          // Reconnect the strings with word break opportunities after double slashes
        )
        .join("//<wbr>");

      return formatted;
    },
    renderValue(value) {
      if (!value) return "-";
      if (Object.prototype.toString.call(value) === "[object String]") {
        return value;
      }
      if (Object.prototype.toString.call(value) === "[object Number]") {
        return value;
      }
      if (Object.prototype.toString.call(value) === "[object Array]") {
        return value.length === 0 ? "-" : value.toString();
      }
    },
  };
});

export default useHelpersStore;
