import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import useBookingStore from "../../store/bookings";
import { useEffect, useState } from "react";
import { set } from "date-fns";
import bookings from "../../_config/dataBookings";

const EditCustomerDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    newCustomer,
    setNewCustomer,
    oldCustomer,
    setOldCustomer,
    openEditCustomerDialog,
    setOpenEditCustomerDialog,
    updateCustomer,
    setSnackBar,
    selectedBooking,
    setBookingsPending,
    setSelectedBooking,
  } = useBookingStore((state) => ({
    newCustomer: state.newCustomer,
    setNewCustomer: state.setNewCustomer,
    openEditCustomerDialog: state.openEditCustomerDialog,
    setOpenEditCustomerDialog: state.setOpenEditCustomerDialog,
    updateCustomer: state.updateCustomer,
    oldCustomer: state.oldCustomer,
    setOldCustomer: state.setOldCustomer,
    setSnackBar: state.setSnackBar,
    selectedBooking: state.selectedBooking,
    setBookingsPending: state.setBookingsPending,
    setSelectedBooking: state.setSelectedBooking
  }));

  const { customer } = selectedBooking;

  useEffect(() => {
    setOldCustomer({
      firstName: customer.first_name,
      lastName: customer.last_name,
      email: customer.email,
    });
    setNewCustomer({
      firstName: customer.first_name,
      lastName: customer.last_name,
      email: customer.email,
    });
  }, []);

  const handleClose = () => {
    setOpenEditCustomerDialog(false);
  }

  const handleCustomerEdit = (event: any) => {
    // update the newCustomer state with the new value from the input field 
    setNewCustomer({
      ...newCustomer,
      [event.target.name]: event.target.value,
    });
  }

  const handleEditCustomer = () => {
    const done = updateCustomer(customer.id);
    // @ts-ignore
    if (done) {
      // handleClose();
      setSnackBar({
        open: true,
        message: "Customer updated successfully",
        type: "success",
      });
      
      setTimeout(() => {
        handleClose();
        setBookingsPending();      
        setSnackBar({
          open: false,
          message: "Customer updated successfully",
          type: "success",
        });
      }
        , 2000);
    } else {
      setSnackBar({
        open: true,
        message: "Error updating customer",
        severity: "error",
      });

      setTimeout(() => {
        handleClose();
        setSnackBar({
          open: false,
          message: "Error updating customer",
          severity: "error",
        });
      }
        , 2000);
    }
  }

  const open = openEditCustomerDialog;

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Edit customer
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="First name"
          name="firstName"
          size="small"
          variant="standard"
          value={newCustomer.firstName}
          onChange={handleCustomerEdit}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Last name"
          name="lastName"
          size="small"
          variant="standard"
          value={newCustomer.lastName}
          onChange={handleCustomerEdit}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          size="small"
          variant="standard"
          value={newCustomer.email}
          onChange={handleCustomerEdit}
          sx={{ marginBottom: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleEditCustomer} autoFocus
          variant="contained"
          color="primary"
          disabled={oldCustomer.firstName === newCustomer.firstName && oldCustomer.lastName === newCustomer.lastName && oldCustomer.email === newCustomer.email}
        >
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCustomerDialog;