import LocalActivityIcon from "@mui/icons-material/LocalActivity";

export const barItems = [
  // {
  //   to: "/tse",
  //   label: "Tarjeta Sanitaria",
  //   icon: LocalHospitalIcon,
  //   opened: false,
  // },
  // {
  //   to: "/uspostal",
  //   label: "US Postal Test",
  //   icon: ContactMailIcon,
  //   opened: false,
  // },
  {
    to: "/hoponhopoff",
    label: "Hop On Hop Off",
    icon: LocalActivityIcon,
    opened: false,
  },
  {
    to: "/acropolis",
    label: "Acropolis",
    icon: LocalActivityIcon,
    opened: false,
  },
  {
    to: "/montjuic",
    label: "Montjuic",
    icon: LocalActivityIcon,
    opened: false,
  },
  {
    to: "/seine",
    label: "Seine",
    icon: LocalActivityIcon,
    opened: false,
  },
  {
    to: "/sagrada",
    label: "Sagrada Familia",
    icon: LocalActivityIcon,
    opened: false,
  },
  {
    to: "/sagradanew",
    label: "Sagrada Familia New",
    icon: LocalActivityIcon,
    opened: false,
  },
  {
    to: "/sagrada-adyen",
    label: "Sagrada Familia Adyen",
    icon: LocalActivityIcon,
    opened: false,
  },
  // {
  //   to: "/testiq",
  //   label: "Test IQ Online",
  //   icon: PollIcon,
  //   opened: false,
  // },
  {
    to: "/colosseum",
    label: "Coliseo",
    icon: LocalActivityIcon,
    opened: false,
  },
  {
    to: "/parkguell",
    label: "Parc Guell",
    icon: LocalActivityIcon,
    opened: false,
  },
  {
    to: "/parkguellnew",
    label: "Parc Guell New",
    icon: LocalActivityIcon,
    opened: false,
  },
  {
    to: "/vatican",
    label: "Vatican",
    icon: LocalActivityIcon,
    opened: false,
  },
  {
    to: "/versailles",
    label: "Versailles",
    icon: LocalActivityIcon,
    opened: false,
  },
  // {
  //   to: "/sagrada2",
  //   label: "New Sagrada",
  //   icon: LocalActivityIcon,
  //   opened: false,
  // },
  // {
  //   to: "/colosseum2",
  //   label: "New Colosseum",
  //   icon: LocalActivityIcon,
  //   opened: false,
  // },
  // {
  //   to: "/vitals",
  //   label: "Vitals",
  //   icon: LocalActivityIcon,
  //   opened: false,
  // },
];
