import { IProject } from "./../interfaces/IProject";
import create from "zustand";
import moment from "moment";
import fileDownload from "js-file-download";
import { NavigateFunction } from "react-router-dom";
import { Buffer } from "buffer";
import { Config } from "../config";
import { reportToXlsx } from "../utils/xlsx";
import axios from "axios";
import to from "await-to-js";
import ParkGuellVars from "../pages/park-guell/config/vars";

interface ICards {
  id: number;
  attempts: number;
  createdAt: string;
  cvv: string;
  month: string;
  numOnHold: number;
  numRevised: number;
  numberCard: number;
  status: string;
  updatedAt: string;
  year: string;
  _id: string;
}

interface SagradaOrder {
  arrErrors: string[];
  attempts: number;
  cardnumber: string;
  categoryTickets: string;
  client: {
    address: string;
    country: string;
    email: string;
    firstName: string;
    lastName: string;
    nif: string;
    phoneNumber: string;
    postcode: string;
  };
  createdAt: string;
  hasAlert: boolean;
  hasBilledSaved: boolean;
  hasTicketsSaved: false;
  logs: {
    accion: string;
    by: string;
    date: string;
    fecha: string;
    hora: string;
    status: string;
  }[];
  purchaseMail: string;
  qontoAmount: number[];
  qontoIds: string[];
  saleId: string;
  sessionIds: string[];
  status: string;
  tickets: {
    type: string;
    amount: number;
  }[];
  ticketsDate: string;
  timeRange: string;
  totalPrice: number;
  trackingNumber: string;
  updatedAt: string;
  urlError: string;
  visitTime: string;
  wc_id: string;
  wc_ref: string;
  _id: string;
}

interface AppStatus {
  toGrid: () => void;
  orders: SagradaOrder[];
  fees: any;
  officialPrice: any;
  filters: {
    minCreatedAt: Date;
    maxCreatedAt: Date;
    status: { [x: string]: boolean };
  };
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  report: { [x: string]: any };
  currentOrderView: "default" | "errors";
  selectedOrder: SagradaOrder | null;
  editForm: { [x: string]: any };
  setEditFoem: (key: { [x: string]: any }) => void;
  openRefundDialog: boolean;
  setOpenRefundDialog: (open: boolean) => void;
  openEditDialog: boolean;
  setOpenEditDialog: (open: boolean) => void;
  openTrackingNumberDialog: boolean;
  setOpenTrackingNumberDialog: (open: boolean) => void;
  openPurchaseDialog: boolean;
  setOpenPurchaseDialog: (open: boolean) => void;
  openUnblockDialog: boolean;
  setOpenUnblockDialog: (open: boolean) => void;
  openAttachFileDialog: boolean;
  setOpenAttachFileDialog: (open: boolean) => void;
  setFilters: (
    project: IProject,
    status: { [x: string]: boolean },
    min?: Date,
    max?: Date
  ) => Promise<boolean>;
  reportDate: {
    minCreatedAt: Date;
    maxCreatedAt: Date;
  };
  projects: {
    name: string;
    alerts: {
      name: string;
      criteria: string[];
      record: string[];
    }[];
  }[];
  loadOrders: (
    project: IProject,
    reloadSelectedOrder?: boolean
  ) => Promise<boolean>;
  loadOrder: (
    project: IProject,
    field: string,
    value: string
  ) => Promise<boolean>;
  errorView: (
    project: IProject,
    navigate: NavigateFunction
  ) => Promise<boolean>;
  defaultView: (
    project: IProject,
    navigate: NavigateFunction
  ) => Promise<boolean>;
  getBills: (project: IProject, _id: string[], filename?: string) => void;
  getTickets: (project: IProject, _id: string[], filename: string) => void;
  saveFile: (
    project: IProject,
    _id: string,
    fileType: string,
    base64String: string
  ) => Promise<boolean>;
  getScreenshotsBot: (project: IProject, wc_ref: string) => void;
  setSelectedOrderByIndex: (index: number) => void;
  setSelectedOrderById: (_id?: string) => void;
  getReport: (
    project: IProject,
    startDate?: string,
    endDate?: string
  ) => Promise<void>;
  getAlerts: (startDate?: string, endDate?: string) => {};
  editOrder: (
    project: IProject,
    changes: string[][],
    _id: string
  ) => Promise<boolean>;
  addLog: (project: IProject, _id: string, arrChanges: string[][]) => void;
  mc2pRefund: (
    project: keyof typeof URLS,
    _id: string,
    refundData: {
      refundType: string;
      amount: number;
      reason: string;
    }
  ) => Promise<boolean>;
  manualPurchase: (
    project: keyof typeof URLS,
    manualPurchaseData: {
      id: string;
      email: string;
      trackingNumber: string;
      cardNumber: string;
    }
  ) => Promise<boolean>;
  manualPurchase2: (
    project: keyof typeof URLS,
    manualPurchaseData: {
      id: string;
      email: string;
      trackingNumber: string;
      card: string;
    }
  ) => Promise<boolean>;
  globalSearch: (
    project: keyof typeof URLS,
    reloadSelectedOrder?: boolean
  ) => void;
  exportCsv: () => void;
  exportCsvRefunds: () => void;
  exportCsv2: (orders: any[], fees: any, officialPrice?: any) => void;
  cards: ICards[];
  getProjectCards: (project: keyof typeof URLS) => Promise<boolean>;
  retryTickets: (project: keyof typeof URLS, _id: string) => Promise<boolean>;
  isPreauthorization: (
    project: keyof typeof URLS,
    saleId: string
  ) => Promise<boolean>;
  unblockTicket: (
    project: keyof typeof URLS,
    saleId: string
  ) => Promise<boolean>;
  unblockTicketAdyen: (
    project: keyof typeof URLS,
    wc_ref: string,
    saleId: string,
    price: number,
    isPreauthorization: boolean
  ) => Promise<boolean>;
  refundTickets: (
    project: keyof typeof URLS,
    minDate: string,
    maxDate: string
  ) => Promise<boolean>;
  addTrackingNumber: (
    project: keyof typeof URLS,
    trackingNumber: string,
    _id: string
  ) => Promise<boolean>;
}

const URLS = {
  sagrada: Config.REACT_APP_API_SAGRADA,
  sagradaAdyen: Config.REACT_APP_API_SAGRADA_ADYEN,
  colosseum: Config.REACT_APP_API_COLOSSEUM,
  alerts: Config.REACT_APP_API_ALERTS,
  parkguell: Config.REACT_APP_API_PARC_GUELL,
  vatican: Config.REACT_APP_API_VATICAN,
  versailles: Config.REACT_APP_API_VERSAILLES,
  acropolis: Config.REACT_APP_API_ACROPOLIS,
  hoponhopoff: Config.REACT_APP_API_HOPONHOPOFF,
  seine: Config.REACT_APP_API_SEINE,
};

const searchOrders = async (
  project: string,
  url: string,
  filters: AppStatus["filters"]
): Promise<[boolean, SagradaOrder[]]> => {
  const statusFilter =
    project !== "sagrada" && project !== "sagradaAdyen"
      ? Object.entries(filters.status)
          .filter((entry) => entry[1])
          .map((entry) => `&status=${entry[0]}`)
          .join("")
      : Object.entries(filters.status)
          .filter((entry) => entry[1])
          .join(",");
  const query = `minDate=${filters.minCreatedAt.toISOString()}&maxDate=${filters.maxCreatedAt.toISOString()}&status=${statusFilter}`;
  const response = await fetch(`${url}/api/orders?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
    },
  });
  if (!response.ok) return [!response.ok, []];
  const data = await response.json();
  console.log("AUX FUNCTION");
  console.log(data);

  return [!response.ok, data];
};

const useOrdersStore = create<AppStatus>((set, get) => {
  return {
    toGrid: () => set(() => ({ selectedOrder: null })),
    fees: ParkGuellVars.fees,
    officialPrice: ParkGuellVars.officialPrice,
    orders: [],
    filters: {
      status: {},
      minCreatedAt: new Date(moment().startOf("day").toISOString()),
      maxCreatedAt: new Date(moment().endOf("day").toISOString()),
    },
    globalFilter: "",
    report: {},
    reportDate: {
      minCreatedAt: new Date(moment().startOf("day").toISOString()),
      maxCreatedAt: new Date(moment().endOf("day").toISOString()),
    },
    currentOrderView: "default",
    selectedOrder: null,
    openRefundDialog: false,
    openEditDialog: false,
    openPurchaseDialog: false,
    openUnblockDialog: false,
    openAttachFileDialog: false,
    openTrackingNumberDialog: false,
    editForm: {},
    projects: [],
    setEditFoem: (newForm) => {
      set(() => ({ editForm: newForm }));
    },
    setFilters: async (project, status, min, max) => {
      set(() => ({
        filters: {
          status,
          minCreatedAt: min || new Date(moment().startOf("day").toISOString()),
          maxCreatedAt: max || new Date(moment().endOf("day").toISOString()),
        },
      }));
      return await get().loadOrders(project);
    },
    setGlobalFilter: (value) => {
      console.log(value);
      set(() => ({ globalFilter: value }));
    },
    loadOrders: async (project, reloadSelectedOrder = false) => {
      const url = URLS[project] as string;
      if (project === "sagrada") {
        const [err, orders] = await searchOrders(project, url, get().filters);
        if (err) return false;
        const gridOrders = orders.map((order, i) => {
          return { id: i, ...order, _id: order._id };
        });
        set({ orders: gridOrders });
        if (reloadSelectedOrder) {
          set((state) => ({
            selectedOrder: gridOrders.find(
              (order) => order._id === (state.selectedOrder?._id as string)
            ),
          }));
        }
        return true;
      } else {
        const [err, orders] = await searchOrders(project, url, get().filters);
        if (err) return false;
        const gridOrders = orders.map((order, i) => {
          return { id: i, ...order, _id: order._id };
        });
        set({ orders: gridOrders });
        if (reloadSelectedOrder) {
          set((state) => ({
            selectedOrder: gridOrders.find(
              (order) => order._id === (state.selectedOrder?._id as string)
            ),
          }));
        }
        return true;
      }
    },
    errorView: async (project) => {
      set((state) => ({
        filters: {
          ...state.filters,
          status: Object.keys(state.filters.status).reduce(
            (acc: { [x: string]: boolean }, key) => {
              acc[key] = ["on hold", "to review"].includes(key) ? true : false;
              return acc;
            },
            {}
          ),
        },
      }));
      return get().loadOrders(project);
    },
    defaultView: async (project) => {
      set((state) => ({
        filters: {
          ...state.filters,
          status: Object.keys(state.filters.status).reduce(
            (acc: { [x: string]: boolean }, key) => {
              acc[key] = true;
              return acc;
            },
            {}
          ),
        },
      }));
      return await get().loadOrders(project);
    },
    getBills: async (project, _id, filename) => {
      const url = URLS[project] as string;
      const query = `?id=${_id.join("&id=")}`;
      const response = await fetch(`${url}/api/orders/bills${query}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
          responseType: "blob",
        },
      });
      if (!response.ok) return;
      const data = await response.blob();

      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        _id.length === 1 ? `${filename}.pdf` : `bills.zip`
      ); //or any other extension
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(`${url}/api/orders/bills${query}`);
    },
    getTickets: async (project, _id, filename) => {
      const url = URLS[project] as string;
      const query = `?id=${_id.join("&id=")}`;
      const response = await fetch(`${url}/api/orders/tickets${query}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
          responseType: "blob",
        },
      });
      if (!response.ok) return;
      const data = await response.blob();

      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        _id.length === 1 ? `${filename}.pdf` : `tickets.zip`
      ); //or any other extension
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(`${url}/api/orders/tickets${query}`);
    },
    saveFile: async (project, _id, fileType, base64String) => {
      console.log(project, _id, fileType, base64String);
      var url = URLS[project] as string;
      url = `${url}/api/orders/${_id}/upload-file/${fileType}`;
      console.log(url);
      const body = {
        base64: base64String,
      };
      console.log("body", body);
      const response = await fetch(url, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
        },
      });
      console.log("response save file", response);
      if (!response.ok) return false;
      return true;
    },
    setSelectedOrderByIndex: (index) => {
      console.log(get().orders[index]);

      set((state) => ({
        selectedOrder: index === -1 ? null : state.orders[index],
      }));
    },
    setSelectedOrderById: () => {
      const orders = get().orders;
      set((state) => ({
        selectedOrder: orders.find(
          (order) => (order._id = state.selectedOrder?._id as string)
        ),
      }));
    },
    getScreenshotsBot: async (project, wc_ref) => {
      const url = URLS[project] as string;
      const response = await fetch(`${url}/api/orders/screenshots/${wc_ref}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
        },
      });
      if (!response.ok) return false;
      const data = await response.json();
      data.forEach((screenshot: string, i: number) => {
        fileDownload(
          Buffer.from(screenshot, "base64"),
          `${i + 1}_${wc_ref}.png`
        );
      });

      return true;
    },
    getReport: async (
      project,
      startDate = moment().startOf("day").toISOString(),
      endDate = moment().endOf("day").toISOString()
    ) => {
      const url = URLS[project] as string;
      console.log(project);
      const query = `minDate=${startDate}&maxDate=${endDate}`;
      const apiURL =
        project === "sagrada"
          ? `${url}/api/orders/report?${query}`
          : `${url}/api/reports?${query}`;

      const response = await fetch(apiURL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
        },
      });
      const data = await response.json();
      console.log("Report");
      console.log(data);

      if (response.ok) set((state) => (state.report = data));
    },
    //TODO: Falta configurar
    getAlerts: async (
      startDate = moment().startOf("day").toISOString(),
      endDate = moment().endOf("day").toISOString()
    ) => {
      const url = URLS["alerts"];
      const query = `minDate=${startDate}&maxDate=${endDate}`;

      const response = await fetch(`${url}/project?${query}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log(data);

      if (response.ok) set((state) => (state.projects = data));
    },
    setOpenRefundDialog: (open) => set(() => ({ openRefundDialog: open })),
    setOpenEditDialog: (open) => set(() => ({ openEditDialog: open })),
    setOpenTrackingNumberDialog: (open) =>
      set(() => ({ openTrackingNumberDialog: open })),
    setOpenPurchaseDialog: (open) => set(() => ({ openPurchaseDialog: open })),
    setOpenUnblockDialog: (open) => set(() => ({ openUnblockDialog: open })),
    setOpenAttachFileDialog: (open) =>
      set(() => ({ openAttachFileDialog: open })),
    loadOrder: async (project, field, value) => {
      const url = URLS[project] as string;
      const query = `${field}=${value}`;
      const response = await fetch(`${url}/api/orders?${query}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
        },
      });
      if (!response.ok) return false;
      const orders = (await response.json()) as SagradaOrder[];
      console.log(orders);
      set({ orders: orders.map((order, i) => ({ id: i, ...order })) });
      return true;
    },
    editOrder: async (project, changes, _id) => {
      const arrTitularProperties = [
        "email",
        "firstName",
        "lastName",
        "phoneNumber",
      ];
      const url = URLS[project] as string;
      const preBody: [string, any][] = changes.map((key) => {
        const field = key[0];
        const newVal = key[2];
        return arrTitularProperties.includes(field)
          ? [`client.${field}`, newVal]
          : [field, newVal];
      });
      const body = Object.fromEntries(new Map(preBody));
      console.log(body);

      const response = await fetch(`${url}/api/orders/${_id}`, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
        },
      });
      if (!response.ok) return false;
      return true;
    },
    mc2pRefund: async (project, _id, refundData) => {
      const url = URLS[project] as string;
      const response = await fetch(`${url}/api/orders/${_id}/refund`, {
        method: "PATCH",
        body: JSON.stringify({
          amount: refundData.amount,
          refundType: refundData.refundType,
          reason: refundData.reason,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
        },
      });
      if (!response.ok) return false;
      return true;
    },
    addLog: async (project, _id, arrChanges) => {
      const url = URLS[project] as string;
      const agent = localStorage.getItem("username");
      const body = arrChanges
        .map((row) => {
          const [field, oldVal, newVal] = row;
          return `${field}||${oldVal}||${newVal}`;
        })
        .join("&&");
      fetch(`${url}/api/orders/logs`, {
        method: "POST",
        body: JSON.stringify({
          _id,
          status: "-",
          accion: "Edit order>>" + body,
          by: agent,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
        },
      });
    },
    manualPurchase: async (project, manualPurchaseData) => {
      const url = URLS[project] as string;
      const agent = localStorage.getItem("username");
      const response = await fetch(`${url}/api/orders/manual`, {
        method: "POST",
        body: JSON.stringify({
          _id: manualPurchaseData.id,
          cardNumber: manualPurchaseData.cardNumber,
          trackingNumber: manualPurchaseData.trackingNumber,
          agent: agent,
          email: manualPurchaseData.email,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
        },
      });
      if (!response.ok) return false;
      const done = await response.json();
      if (!done) return false;
      return true;
    },
    manualPurchase2: async (project, manualPurchaseData) => {
      const url = URLS[project] as string;
      const agent = localStorage.getItem("username");
      // const form = new FormData();
      // form.append("file", manualPurchaseData.file, "tickets.pdf");
      // form.append("_id", manualPurchaseData.id);
      // form.append("qontoId", manualPurchaseData.qontoId);
      // form.append("trackingNumber", manualPurchaseData.trackingNumber);
      // form.append("email", manualPurchaseData.email);
      // form.append("agent", <string>agent);

      const response = await axios.post<boolean>(
        `${url}/api/orders/manual`,
        {
          _id: manualPurchaseData.id,
          card: manualPurchaseData.card,
          trackingNumber: manualPurchaseData.trackingNumber,
          agent: agent,
          email: manualPurchaseData.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
          },
        }
      );

      if (response.status !== 201) return false;
      const done = response.data;
      if (!done) return false;
      return true;
    },
    globalSearch: async (project, reloadSelectedOrder = false) => {
      const url = URLS[project];
      console.log(url);
      console.log(get().globalFilter);

      const response = await fetch(
        `${url}/api/orders/global?value=${get().globalFilter}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
          },
        }
      );
      if (!response.ok) return;
      const orders: SagradaOrder[] = await response.json();
      const gridOrder = orders.map((order, i) => ({ id: i, ...order }));
      set({ orders: gridOrder });
      if (reloadSelectedOrder) {
        set((state) => ({
          selectedOrder: gridOrder.find(
            (order) => order._id === (state.selectedOrder?._id as string)
          ),
        }));
      }
    },
    exportCsv() {
      const orders = get().orders;
      const fees: any = {
        "Parc Guell Tickets": {
          adult: 6,
          reduced: 6,
          free: 0,
        },
        "Parc Guell Tickets Guided EN": {
          adult: 10,
          reduced: 10,
          free: 0,
        },
        "Parc Guell Tickets Guided ES": {
          adult: 10,
          reduced: 10,
          free: 0,
        },
        "Parc Guell Tickets Guided FR": {
          adult: 10,
          reduced: 10,
          free: 0,
        },
        "Parc Guell Tickets Guided IT": {
          adult: 10,
          reduced: 10,
          free: 0,
        },
      };
      reportToXlsx(
        orders.map((order: any) => {
          const adult = order.tickets.find((el: any) => el.type === "adult");
          const entry = order.tickets.find((el: any) => el.type === "entrada");
          const entryReduced = order.tickets.find(
            (el: any) => el.type === "entradaReducida"
          );
          const under30 = order.tickets.find(
            (el: any) => el.type === "under 30 years old"
          );
          const under11 = order.tickets.find(
            (el: any) => el.type === "under 11 years old"
          );
          const free = order.tickets.find((el: any) => el.type === "free");
          const reduced = order.tickets.find(
            (el: any) => el.type === "reduced"
          );
          let amountFee = 0;
          if (adult)
            amountFee =
              amountFee + fees["Parc Guell Tickets"]["adult"] * adult.amount;
          if (free)
            amountFee =
              amountFee + fees["Parc Guell Tickets"]["free"] * free.amount;
          if (reduced)
            amountFee =
              amountFee +
              fees["Parc Guell Tickets"]["reduced"] * reduced.amount;
          return {
            ...(order.createdAt && {
              "Created At": moment(order.createdAt).format(
                "DD/MM/YYYY - HH:mm"
              ),
            }),
            ...(order.status && { Status: order.status }),
            ...(order.client.firstName && {
              "First Name": order.client.firstName,
            }),
            ...(order.client.lastName && {
              "Last Name": order.client.lastName,
            }),
            ...(order.client.firstName &&
              order.client.lastName && {
                Client: order.client.firstName + " " + order.client.lastName,
              }),
            ...(order.client.email && {
              Email: order.client.email,
            }),
            ...(order.client.phoneNumber && {
              "Phone Number": order.client.phoneNumber,
            }),
            ...(order.client.address && {
              Address: order.client.address,
            }),
            ...(order.client.postcode && {
              Postcode: order.client.postcode,
            }),
            ...(order.client.country && {
              Country: order.client.country,
            }),
            ...(order.client.nif && {
              "ID Number": order.client.nif,
            }),
            ...(order.categoryTickets && {
              "Ticket Type": order.categoryTickets,
            }),
            ...(order.cardnumber && {
              "Card Number": `**** **** **** ${order.cardnumber.slice(-4)}`,
            }),
            ...(order.qontoIds &&
              order.qontoIds.length && {
                "Transactional ID": order.qontoIds
                  .map((el: any) => String(el))
                  .join(", "),
              }),
            ...(order.totalPrice &&
              amountFee && {
                "Amount Supplied": order.totalPrice - amountFee,
              }),
            ...(order.tickets && { Fee: amountFee }),
            ...(order.totalPrice && { "Amount Paid": order.totalPrice }),
            ...(order.wc_id && { "Order ID": order.wc_id }),
            ...(order.timeRange && { "Time Range": order.timeRange }),
            ...(order.visitTime && { "Visit Time": order.visitTime }),
            ...(order.ticketsDate && { "Visit Date": order.ticketsDate }),
            ...(order.refund && {
              "Refund Date": moment(order.refund.time).format(
                "DD/MM/YYYY - HH:mm"
              ),
            }),
            ...(adult && { "T. Adults": adult.amount }),
            ...(entry && { "T. Adults": entry.amount }),
            ...(reduced && { "T. Reduced": reduced.amount }),
            ...(entryReduced && { "T. Reduced": entryReduced.amount }),
            ...(under30 && { "T. Reduced": under30.amount }),
            ...(under11 && { "T. Children": under11.amount }),
            ...(free && { "T. Children": free.amount }),
          };
        })
      );
    },
    exportCsvRefunds() {
      const orders = get().orders.filter((order) => order.status === "refund");
      const fees: any = {
        "Parc Guell Tickets": {
          adult: 6,
          reduced: 6,
          free: 0,
        },
        "Parc Guell Tickets Guided EN": {
          adult: 10,
          reduced: 10,
          free: 0,
        },
        "Parc Guell Tickets Guided ES": {
          adult: 10,
          reduced: 10,
          free: 0,
        },
        "Parc Guell Tickets Guided FR": {
          adult: 10,
          reduced: 10,
          free: 0,
        },
        "Parc Guell Tickets Guided IT": {
          adult: 10,
          reduced: 10,
          free: 0,
        },
      };
      reportToXlsx(
        orders.map((order: any) => {
          const adult = order.tickets.find((el: any) => el.type === "adult");
          const entry = order.tickets.find((el: any) => el.type === "entrada");
          const entryReduced = order.tickets.find(
            (el: any) => el.type === "entradaReducida"
          );
          const under30 = order.tickets.find(
            (el: any) => el.type === "under 30 years old"
          );
          const under11 = order.tickets.find(
            (el: any) => el.type === "under 11 years old"
          );
          const free = order.tickets.find((el: any) => el.type === "free");
          const reduced = order.tickets.find(
            (el: any) => el.type === "reduced"
          );
          let amountFee = 0;

          const guidedTour = order.categoryTickets.includes("Guided");

          if (guidedTour) {
            if (adult)
              amountFee =
                amountFee +
                fees["Parc Guell Tickets Guided"]["adult"] * adult.amount;
            if (free)
              amountFee =
                amountFee +
                fees["Parc Guell Tickets Guided"]["free"] * free.amount;
            if (reduced)
              amountFee =
                amountFee +
                fees["Parc Guell Tickets Guided"]["reduced"] * reduced.amount;
          } else {
            if (adult)
              amountFee =
                amountFee + fees["Parc Guell Tickets"]["adult"] * adult.amount;
            if (free)
              amountFee =
                amountFee + fees["Parc Guell Tickets"]["free"] * free.amount;
            if (reduced)
              amountFee =
                amountFee +
                fees["Parc Guell Tickets"]["reduced"] * reduced.amount;
          }

          return {
            ...(order.createdAt && {
              "Created At": moment(order.createdAt).format(
                "DD/MM/YYYY - HH:mm"
              ),
            }),
            ...(order.status && { Status: order.status }),
            ...(order.client.firstName && {
              "First Name": order.client.firstName,
            }),
            ...(order.client.lastName && {
              "Last Name": order.client.lastName,
            }),
            ...(order.client.firstName &&
              order.client.lastName && {
                Client: order.client.firstName + " " + order.client.lastName,
              }),
            ...(order.client.email && {
              Email: order.client.email,
            }),
            ...(order.client.phoneNumber && {
              "Phone Number": order.client.phoneNumber,
            }),
            ...(order.client.address && {
              Address: order.client.address,
            }),
            ...(order.client.postcode && {
              Postcode: order.client.postcode,
            }),
            ...(order.client.country && {
              Country: order.client.country,
            }),
            ...(order.client.nif && {
              "ID Number": order.client.nif,
            }),
            ...(order.categoryTickets && {
              "Ticket Type": order.categoryTickets,
            }),
            ...(order.cardnumber && {
              "Card Number": `**** **** **** ${order.cardnumber.slice(-4)}`,
            }),
            ...(order.qontoIds &&
              order.qontoIds.length && {
                "Transactional ID": order.qontoIds
                  .map((el: any) => String(el))
                  .join(", "),
              }),
            ...(order.totalPrice &&
              amountFee && {
                "Amount Supplied": order.totalPrice - amountFee,
              }),
            ...(order.tickets && { Fee: amountFee }),
            ...(order.totalPrice && { "Amount Paid": order.totalPrice }),
            ...(order.wc_id && { "Order ID": order.wc_id }),
            ...(order.timeRange && { "Time Range": order.timeRange }),
            ...(order.visitTime && { "Visit Time": order.visitTime }),
            ...(order.ticketsDate && { "Visit Date": order.ticketsDate }),
            ...(order.refund && {
              "Refund Date": moment(order.refund.time).format(
                "DD/MM/YYYY - HH:mm"
              ),
            }),
            ...(adult && { "T. Adults": adult.amount }),
            ...(entry && { "T. Adults": entry.amount }),
            ...(reduced && { "T. Reduced": reduced.amount }),
            ...(entryReduced && { "T. Reduced": entryReduced.amount }),
            ...(under30 && { "T. Reduced": under30.amount }),
            ...(under11 && { "T. Children": under11.amount }),
            ...(free && { "T. Children": free.amount }),
          };
        })
      );
    },
    exportCsv2(objXlsx, fees, officialPrice?) {
      if (officialPrice) console.log("officialPrice", officialPrice);
      reportToXlsx(
        objXlsx.map((order) => {
          var freeAmountAcro = 0;
          var reduceAmountAcro = 0;
          const adult = order.tickets.find((el: any) => el.type === "adult");
          const entry = order.tickets.find((el: any) => el.type === "entrada");
          const entryReduced = order.tickets.find(
            (el: any) => el.type === "entradaReducida"
          );
          const under30 = order.tickets.find(
            (el: any) => el.type === "under 30 years old"
          );
          const under11 = order.tickets.find(
            (el: any) => el.type === "under 11 years old"
          );
          const free = order.tickets.find((el: any) => el.type === "free");
          const reduced = order.tickets.find(
            (el: any) => el.type === "reduced"
          );
          const nonyouths = order.tickets.find(
            (el: any) => el.type === "nonyouths"
          );
          const reducedover65 = order.tickets.find(
            (el: any) => el.type === "reducedover65"
          );
          const euto25 = order.tickets.find((el: any) => el.type === "euto25");
          const noneuto5 = order.tickets.find(
            (el: any) => el.type === "noneuto5"
          );
          const junior = order.tickets.find((el: any) => el.type === "junior");
          const senior = order.tickets.find((el: any) => el.type === "senior");
          let categFee = fees[order.categoryTickets];
          let amountFee = 0;
          let amountSupplied = 0;
          //console.log(officialPrice[order.categoryTickets]);
          if (officialPrice) {
            const suppliedCateg =
              officialPrice[order.categoryTickets] ??
              officialPrice["SAGRADA FAMILIA"];
            if (adult)
              amountSupplied =
                amountSupplied + suppliedCateg["adult"] * adult.amount;
            if (entry)
              amountSupplied =
                amountSupplied + suppliedCateg["entrada"] * entry.amount;
            if (entryReduced)
              amountSupplied =
                amountSupplied +
                suppliedCateg["entradaReducida"] * entryReduced.amount;
            if (under30)
              amountSupplied =
                amountSupplied +
                suppliedCateg["under 30 years old"] * under30.amount;
            if (under11)
              amountSupplied =
                amountSupplied +
                suppliedCateg["under 11 years old"] * under11.amount;
            if (free)
              amountSupplied =
                amountSupplied + suppliedCateg["free"] * free.amount;
            if (reduced)
              amountSupplied =
                amountSupplied + suppliedCateg["reduced"] * reduced.amount;
          }
          if (
            order.categoryTickets.includes("SAGRADA FAMILIA") &&
            new Date(order.createdAt).getTime() >
              new Date(2023, 4, 15, 22, 4, 0).getTime()
          ) {
            categFee = categFee ? categFee : fees["SAGRADA FAMILIA"];
            if (adult) amountFee = amountFee + categFee["adult"] * adult.amount;
            if (entry)
              amountFee = amountFee + categFee["entrada"] * entry.amount;
            if (entryReduced)
              amountFee =
                amountFee + categFee["entradaReducida"] * entryReduced.amount;
            if (under30)
              amountFee =
                amountFee + categFee["under 30 years old"] * under30.amount;
            if (under11)
              amountFee =
                amountFee + categFee["under 11 years old"] * under11.amount;
            if (free) amountFee = amountFee + categFee["free"] * free.amount;
            if (reduced)
              amountFee = amountFee + categFee["reduced"] * reduced.amount;
          } else {
            categFee = categFee ? categFee : fees["SAGRADA FAMILIA"];
            if (adult) amountFee = amountFee + categFee["adult"] * adult.amount;
            if (entry)
              amountFee = amountFee + categFee["entrada"] * entry.amount;
            if (entryReduced)
              amountFee =
                amountFee + categFee["entradaReducida"] * entryReduced.amount;
            if (under30)
              amountFee =
                amountFee +
                (categFee["under 30 years old"] + 2) * under30.amount;
            if (under11)
              amountFee =
                amountFee + categFee["under 11 years old"] * under11.amount;
            if (free) amountFee = amountFee + categFee["free"] * free.amount;
            if (reduced)
              amountFee = amountFee + categFee["reduced"] * reduced.amount;
            if (nonyouths) {
              amountFee = amountFee + categFee["nonyouths"] * nonyouths.amount;
              reduceAmountAcro = nonyouths.amount;
            }
            if (reducedover65) {
              amountFee =
                amountFee + categFee["reducedover65"] * reducedover65.amount;
              reduceAmountAcro += reducedover65.amount;
            }
            if (euto25) {
              amountFee = amountFee + categFee["euto25"] * euto25.amount;
              freeAmountAcro = euto25.amount;
            }
            if (noneuto5) {
              amountFee = amountFee + categFee["noneuto5"] * noneuto5.amount;
              freeAmountAcro += noneuto5.amount;
            }
            if (junior) {
              amountFee = amountFee + categFee["junior"] * junior.amount;
              freeAmountAcro += junior.amount;
            }
            if (senior) {
              amountFee = amountFee + categFee["senior"] * senior.amount;
              freeAmountAcro += senior.amount;
            }
          }
          return {
            ...(order.createdAt && {
              "Created At": moment(order.createdAt).format(
                "DD/MM/YYYY - HH:mm"
              ),
            }),
            ...(order.status && { Status: order.status }),
            ...(order.client.firstName && {
              "First Name": order.client.firstName,
            }),
            ...(order.client.lastName && {
              "Last Name": order.client.lastName,
            }),
            ...(order.client.firstName &&
              order.client.lastName && {
                Client: order.client.firstName + " " + order.client.lastName,
              }),
            ...(order.client.email && {
              Email: order.client.email,
            }),
            ...(order.client.phoneNumber && {
              "Phone Number": order.client.phoneNumber,
            }),
            ...(order.client.address && {
              Address: order.client.address,
            }),
            ...(order.client.postcode && {
              Postcode: order.client.postcode,
            }),
            ...(order.client.country && {
              Country: order.client.country,
            }),
            ...(order.client.nif && {
              "ID Number": order.client.nif,
            }),
            ...(order.categoryTickets && {
              "Ticket Type": order.categoryTickets,
            }),
            ...(order.cardnumber && {
              "Card Number": `**** **** **** ${order.cardnumber.slice(-4)}`,
            }),
            ...(order.qontoIds &&
              order.qontoIds.length && {
                "Transactional ID": order.qontoIds
                  .map((el: any) => String(el))
                  .join(", "),
              }),
            ...(order.totalPrice &&
              amountFee && {
                "Amount Supplied": officialPrice
                  ? amountSupplied
                  : order.totalPrice - amountFee,
              }),
            ...(order.tickets && {
              Fee: officialPrice
                ? order.totalPrice - amountSupplied
                : amountFee,
            }),
            ...(order.totalPrice && { "Amount Paid": order.totalPrice }),
            ...(order.wc_id && { "Order ID": order.wc_id }),
            ...(order.timeRange && { "Time Range": order.timeRange }),
            ...(order.visitTime && { "Visit Time": order.visitTime }),
            ...(order.ticketsDate && { "Visit Date": order.ticketsDate }),
            ...(order.refund && {
              "Refund Date": moment(order.refund.time).format(
                "DD/MM/YYYY - HH:mm"
              ),
            }),
            ...(adult && { "T. Adults": adult.amount }),
            ...(entry && { "T. Adults": entry.amount }),
            ...(reduced && { "T. Reduced": reduced.amount }),
            ...(entryReduced && { "T. Reduced": entryReduced.amount }),
            ...(under30 && { "T. Reduced": under30.amount }),
            ...(under11 && { "T. Children": under11.amount }),
            ...(free && { "T. Children": free.amount }),
            ...(junior && { "T. Junior": junior.amount }),
            ...(senior && { "T. Senior": senior.amount }),
          };
        })
      );
    },
    cards: [],
    async getProjectCards(project) {
      const url = URLS[project];
      const [err, response] = await to(
        axios.get<ICards[]>(`${url}/api/card`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
          },
        })
      );
      if (err) return false;
      const cards = response.data.map((card, i) => ({ ...card, id: i + 1 }));
      console.log(cards);

      set(() => ({ cards: cards }));
      return true;
    },
    async retryTickets(project, _id) {
      const url = URLS[project];
      const [err, response] = await to(
        axios.patch(
          `${url}/api/orders/${_id}/retrieve/tickets`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                `${project}Token`
              )}`,
            },
          }
        )
      );
      if (err) return false;
      if (!response.data) return false;
      return true;
    },
    async isPreauthorization(project, saleId) {
      const url = URLS[project];
      const [err, response] = await to(
        axios.post(
          `${url}/api/payment-method/is-preauthorization/${saleId}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                `${project}Token`
              )}`,
            },
          }
        )
      );
      if (err) return false;
      //console.log("res", response.data);
      if (!response.data) return false;
      return true;
    },
    async unblockTicket(project, _id) {
      const url = URLS[project];
      const [err, response] = await to(
        axios.post(
          `${url}/api/orders/${_id}/payment/drop`,
          { by: localStorage.getItem("username") },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                `${project}Token`
              )}`,
            },
          }
        )
      );
      console.log("res", response);
      console.log("err", err);
      if (err) return false;
      if (!response.data) return false;
      return true;
    },

    async unblockTicketAdyen(
      project,
      wc_ref,
      saleId,
      price,
      isPreauthorization
    ) {
      const url = URLS[project];
      const query = `value=${price * 100}&action=${
        isPreauthorization ? "cancels" : "refunds"
      }`;
      const [err, response] = await to(
        axios.post(
          `${url}/api/payment-method/drop/${wc_ref}/${saleId}?${query}`,
          { by: localStorage.getItem("username") },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(
                `${project}Token`
              )}`,
            },
          }
        )
      );
      if (err) return false;
      if (!response.data) return false;
      return true;
    },
    async refundTickets(project, minDate, maxDate) {
      const url = "https://rprtng.crlanjdrktity.com";
      const query = `project=${project}&minDate=${minDate}&maxDate=${maxDate}`;
      const [err, response] = await to(
        axios.get(`${url}/reports/refund-list?${query}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
          },
        })
      );
      if (err) return false;
      if (!response.data) return false;
      console.log("refunds", response.data);
      return true;
    },
    async addTrackingNumber(project, trackingNumber, _id) {
      console.log("trackingNumber", trackingNumber);
      console.log("_id", _id);
      console.log("project", project);

      const url = URLS[project] as string;

      const response = await fetch(`${url}/api/orders/${_id}`, {
        method: "PATCH",
        body: JSON.stringify({
          trackingNumber: trackingNumber,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
        },
      });

      if (!response.ok) return false;

      return true;
    },
  };
});

export default useOrdersStore;
