import { useState } from "react";
import useSupportStore from "../store/support";
import useBookingStore from "../store/bookings";

export function useUdpatePurchaseProvider() {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const { setPurchaseProvider } = useBookingStore();
    const { API_URL } = useSupportStore();

    const updatePurchaseProvider = async (id: string) => {
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/purchase-provider/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('ticketdoorToken')}`
                }
            })
            const data = await response.json();
            console.log('data purchase provider:', data);
            if (data.statusCode) {
                if (data.statusCode !== 200) {
                    setErrorMsg("Error getting purchase provider");
                }
            } else {
                console.log("hola")
                setPurchaseProvider(data);
            }
            setLoading(false);
        } catch (error) {
            setErrorMsg("Error getting purchase provider");
            setLoading(false);
        }
    }

    return { updatePurchaseProvider, errorMsg, loading };
} 