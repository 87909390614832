const bookingHandlers = [
  {
    id: "1bb354cb-jdg6-7876-6t62-87yehd7e6ye6",
    created_at: "2023-10-31T00:00:00.000Z",
    status: "Processing",
    booking: {
      created_at: "2023-10-31T00:00:00.000Z",
      status: "Processing",
      id: "1bbrg5cb-jdg6-1234-rfrf-5gtrhd7e6ye6",
      purchase_language: "en",
      purchase_domain: "domain.com",
      customer: {
        first_name: "John",
        last_name: "Doe",
        email: "johndoe@gmail.com",
        phone: "+34684451997",
        country: "US",
        city: "New York",
        address: "Wall Street",
        postal_code: "123456",
        dni: "123456789",
        id: "0f452f8f-1e64-4029-8ed1-f71db2b07500"
      },
      cart: {
        total_price: 20,
        items: [
          {
            name: "park - Adult",
            quantity: 1,
            options: [
              {
                name: "EntryDate",
                value: "2023-10-31T00:00:00.000Z",
              },
              {
                name: "EntryTime",
                value: "11:00",
              }
            ]
          },
          {
            name: "park - Reduced",
            quantity: 2,
            options: [
              {
                name: "EntryDate",
                value: "2023-10-31T00:00:00.000Z",
              },
              {
                name: "EntryTime",
                value: "11:00",
              }
            ]
          }
        ],
        activity: {
          tag: "Entry ticket",
          name: "Sagrada Familia Tour",
          venue: {
            tag: "Religious sites",
            name: "sagrada",
          },
          is_bundle: false,
          discount: 0,
          variants: [
            {
              name: "park - Adult",
              options: [
                {
                  name: "EntryDate",
                  value: "2023-10-31T00:00:00.000Z",
                },
                {
                  name: "EntryTime",
                  value: "10:00",
                }
              ]
            }
          ]
        },
        venue: {
          name: "Sagrada Familia",
        },
        participant: {
          name: "Participant name",
          description: "Participant description",
        },
        pricing: {
          total_price: 20,
          dynamic_price: false,
          fee_percentage: 10,
          official_price: 10,
        }
      },
      payment: {
        created_at: "2023-10-31T00:00:00.000Z",
        update_at: "2023-10-31T00:00:00.000Z",
        status: "Success",
        provider_paymeny_id: "564rg5ty-ytgr-iu89-qw32-qwsahd7430ol",
        id: "1bbrg5ty-trg3-werf-mnbv-5gtrhd7e6ye6",
        amount: {
          currency: "EUR",
          value: 20,
        }
      }
    },
    purchase_providers: [
      {
        created_at: "2023-10-31T00:00:00.000Z",
        update_at: "2023-10-31T00:00:00.000Z",
        id: "qasxplo9-ewsd-dhyu-vbmc-5gtrhd7eiuyt",
        name: "Adyen",
        status: "Processing",
        error_tag: "Block card",
        itemsIds: [],
        tracking_number: "53747421",
        entry_time: "11:00",
        total_paid: 20,
        payment_provider: {
          payment_id: "42434154135",
          name: "Adyen"
        },
        purchase_email: "johndoe@gmail.com",
        attempts: 1,
        url_bill: "url_bill",
        logs: [
          {
            id: "42434154135",
            created_at: "2023-10-31T00:00:00.000Z",
            message: "Change status to Processing",
            by: "bot",
            status: "Processing",
          }
        ],
        notifications: [
          {
            id: "821374-fdy278ref-127dsfud",
            environment: "Outbound",
            status: "Pending",
            message: "MailingCreate",
            message_id: "12334324312123",
            communication_type: "email confirmation",
            created_at: "2023-11-06T12:59:23.393Z"
          },
          {
            id: "821374-fdy278f-127sadgw12",
            environment: "Outbound",
            status: "Error",
            message: "MailingError: blocked",
            message_id: "12334324312123",
            communication_type: "email confirmation",
            created_at: "2023-11-06T13:02:23.393Z"
          },
        ],
        by: "BOT",
        message: "Change status to Processing",
      }
    ],
  },
  {
    id: "1bb354cb-1cce-4273-b2b2-ba2279680c9e",
    status: "Processing",
    booking: {
      created_at: "2023-10-31T00:00:00.000Z",
      status: "Success",
      id: "3edrft54-1we3-rf34-b2b2-12we34f5g67y",
      purchase_language: "en",
      purchase_domain: "domain.com",
      customer: {
        first_name: "Mary",
        last_name: "Lamb",
        email: "marylamb@gmail.com",
        phone: "123456789",
        country: "US",
        city: "New York",
        address: "Wall Street",
        postal_code: "123456",
        dni: "123456789",
      },
      cart: {
        total_price: 20,
        items: [
          {
            name: "Product 1",
            quantity: 1,
            options: [
              {
                name: "EntryDate",
                value: "2023-10-31T00:00:00.000Z",
              },
              {
                name: "EntryTime",
                value: "10:00",
              }
            ]
          }
        ],
        activity: {
          tag: "Entry ticket",
          name: "Park Guell Tour",
          is_bundle: false,
          discount: 0,
          venue: {
            tag: "Park",
            name: "park",
          }
        },
        venue: {
          name: "Venue name",
        },
        participant: {
          name: "Participant name",
          description: "Participant description",
        },
        pricing: {
          total_price: 20,
          dynamic_price: false,
          fee_percentage: 10,
          official_price: 10,
        }
      },
      payment: {
        created_at: "2023-10-31T00:00:00.000Z",
        update_at: "2023-10-31T00:00:00.000Z",
        status: "Success",
        provider_paymeny_id: "564rg5ty-ytgr-iu89-qw32-qwsahd7430ol",
        id: "1bbrg5ty-trg3-werf-mnbv-5gtrhd7e6ye6",
        amount: {
          currency: "EUR",
          value: 20,
        }
      }
    },
    purchase_providers: [
      {
        status: "Processing",
        tracking_number: "123456789",
        entry_time: "2023-10-31T00:00:00.000Z",
        total_paid: 20,
        payment_provider: {
          payment_id: "42434154135",
          name: "Adyen"
        },
        attempts: 1,
        url_bill: "url_bill",
        logs: [
          {
            id: "42434154135",
            created_at: "2023-10-31T00:00:00.000Z",
            message: "Change status to Processing",
            by: "bot",
            status: "Processing",
          }
        ],
        notifications: [
          {
            status: "Processing",
            message: "Message from notification",
            comunication_type: "email",
          }
        ]
      }
    ],
  },
  {
    id: "1bb354cb-1cce-4273-b2b2-ba2279680c92",
    status: "Processing",
    booking: {
      created_at: "2023-10-31T00:00:00.000Z",
      status: "Error",
      id: "3edrft54-1we3-rf34-b2b2-12we34f5g67p",
      purchase_language: "en",
      purchase_domain: "domain.com",
      customer: {
        first_name: "Raul",
        last_name: "Ramos",
        email: "raul@gmail.com",
        phone: "123456789",
        country: "US",
        city: "New York",
        address: "Wall Street",
        postal_code: "123456",
        dni: "123456789",
      },
      cart: {
        total_price: 20,
        items: [
          {
            name: "Park Guell Tour",
            quantity: 1,
            options: [
              {
                name: "EntryDate",
                value: "2023-10-31T00:00:00.000Z",
              },
              {
                name: "EntryTime",
                value: "14:00",
              }
            ]
          }
        ],
        activity: {
          tag: "Entry ticket",
          name: "Park Guell Tour",
          is_bundle: false,
          discount: 0,
          venue: {
            tag: "Park",
            name: "park",
          }
        },
        venue: {
          name: "Park Guell",
        },
        participant: {
          name: "Participant name",
          description: "Participant description",
        },
        pricing: {
          total_price: 20,
          dynamic_price: false,
          fee_percentage: 10,
          official_price: 10,
        }
      },
      payment: {
        created_at: "2023-10-31T00:00:00.000Z",
        update_at: "2023-10-31T00:00:00.000Z",
        status: "Success",
        provider_paymeny_id: "564rg5ty-ytgr-iu89-qw32-qwsahd7430ol",
        id: "1bbrg5ty-trg3-werf-mnbv-5gtrhd7e6ye6",
        amount: {
          currency: "EUR",
          value: 20,
        }
      }
    },
    purchase_providers: [
      {
        status: "Processing",
        tracking_number: "123456789",
        entry_time: "2023-10-31T00:00:00.000Z",
        total_paid: 20,
        payment_provider: {
          payment_id: "42434154135",
          name: "Adyen"
        },
        attempts: 1,
        url_bill: "url_bill",
        logs: [
          {
            id: "42434154135",
            created_at: "2023-10-31T00:00:00.000Z",
            message: "Change status to Processing",
            by: "bot",
            status: "Processing",
          }
        ],
        notifications: [
          {
            status: "Processing",
            message: "Message from notification",
            comunication_type: "email",
          }
        ]
      }
    ],
  },
]

export default bookingHandlers;