import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, TextField, useMediaQuery } from "@mui/material";
import useBookingStore from "../../store/bookings";
import { useState } from "react";
import FileInput from "../Inputs/FileInput";
import { useNavigate } from "react-router-dom";

const UploadFileDialog = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    openUploadFileDialog,
    setOpenUploadFileDialog,
    uploadFiles,
    uploadFile,
    fileTypeToUpload,
    setSnackBar
  } = useBookingStore((state) => ({
    openUploadFileDialog: state.openUploadFileDialog,
    setOpenUploadFileDialog: state.setOpenUploadFileDialog,
    uploadFiles: state.uploadFiles,
    uploadFile: state.uploadFile,
    fileTypeToUpload: state.fileTypeToUpload,
    setSnackBar: state.setSnackBar,
  }));

  const handleClose = () => {
    setOpenUploadFileDialog(false);
  }

  const handleUpload = async () => {
    setLoading(true);
    console.log("handleUpload");
    await uploadFile();
    setLoading(false);
    setOpenUploadFileDialog(false);
  }

  return (
    <Dialog
      fullWidth
      open={openUploadFileDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Upload {fileTypeToUpload === "tickets" ? "Tickets" : "Bill"}
      </DialogTitle>
      <DialogContent>
        <FileInput />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleUpload} autoFocus
          disabled={uploadFiles.length === 0 || loading}
        >
          Upload {fileTypeToUpload === "tickets" ? "Tickets" : "Bill"}
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileDialog;