import { useState } from "react";
import useSupportStore from "../store/support";

export const useRemoveFile = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { API_URL } = useSupportStore();
    const header = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("ticketdoorToken")}`,
    }

    const removeFile = async (fileManagementId: string, fileType: string) => {
        console.log("fileManagementId", fileManagementId);
        var body = {}
        if (fileType === "tickets") {
            body = {
                has_tickets: false
            }
        }

        if (fileType === "bills") {
            body = {
                has_bill: false
            }
        }

        setLoading(true);
        try {
            console.log("removing file");
            const response = await fetch(`${API_URL}/file-management/${fileManagementId}`, {
                method: "PATCH",
                headers: header,
                body: JSON.stringify(body),
            });
            const data = await response.json();
            console.log("data remove file", data);
        } catch (error) {
            console.error("Error removing file", error);
        } finally {
            setLoading(false);
        }
    };

    return { loading, removeFile };
};