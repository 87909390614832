import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface Props {
  data: any;
}

export const OneBarChart = (props: Props) => {
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={props.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          type="number"
          domain={([dataMin, dataMax]) => {
            return [0, dataMax];
          }}
        />
        <Tooltip />
        <Legend />
        <Bar dataKey="perc" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};
