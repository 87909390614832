import { Alert, Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import useModalsStore from "../../store/modals";
import useBookingStore from "../../store/bookings";
import { usePostComment } from "../../hooks/usePostComment";
import { LoadingCircle } from "../LoadingCircle";
import { useGetBooking } from "../../hooks/useGetBooking";
import { useEffect } from "react";
import { useSnackBar } from "../../hooks/useSnackBar";

const ContactCustomerDialog = () => {
  const {
    openContactCustomerDialog,
    setOpenContactCustomerDialog,
  } = useModalsStore();

  const { idBookingToContact } = useBookingStore();
  const { postComment, loading: postCommentLoading, errorMsg } = usePostComment();
  const { getBooking, loading: getBookingLoading, error } = useGetBooking()
  const { showSnackBar } = useSnackBar();
  const { API_PROD_URL } = useBookingStore();
  const defaultContactMessage = "Booking has been as contacted";

  const handleClose = () => {
    setOpenContactCustomerDialog(false);
  }

  const handleContact = async () => {
    console.log("Contacted");
    console.log("idBookingToContact: ", idBookingToContact);
    try {
      // get booking by id
      const booking = await getBooking(idBookingToContact);
      console.log('booking:', booking);
      // post comment
      const status = booking?.booking_handler?.purchases_providers[0]?.status;
      const purchaseProviderId = booking?.booking_handler?.purchases_providers[0]?.id;

      console.log('status:', status);
      console.log('purchaseProviderId:', purchaseProviderId);

      if (!status || !purchaseProviderId) {
        showSnackBar("Error marking booking as contacted", "error");
        return;
      }
      await postComment({
        comment: defaultContactMessage, 
        statusProp: status,
        purchaseProviderIdProp: purchaseProviderId,
        type: "Contacted"
      });
      setOpenContactCustomerDialog(false);
    } catch (error) {
      console.error('Error:', error);
      showSnackBar("Error marking booking as contacted", "error");
    }
  }

  return (
    <Dialog
      fullWidth
      open={openContactCustomerDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        Are you sure you want to mark this order as contacted?
      </DialogContent>
      <DialogActions>
        <Box display={"flex"} gap={"1rem"}>
          <Box display={"flex"} gap={"1rem"}>
            <Button autoFocus onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleContact} disabled={getBookingLoading || postCommentLoading}>
              Yes
              {getBookingLoading || postCommentLoading && <LoadingCircle />}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ContactCustomerDialog;