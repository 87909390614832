import { Checkbox } from "@mui/material";
import useBookingStore from "../store/bookings";
import useModalsStore from "../store/modals";
import { blue } from "@mui/material/colors";

export const CheckboxContactCustomer = ({ id, value }: { id: string, value: boolean }) => {
    const Store =  useModalsStore();
    const StoreBooking = useBookingStore();

    function handleContactClick() {    
      Store.setOpenContactCustomerDialog(true);
      StoreBooking.setIdBookingToContact(id);
    }
  
    return (    
      <Checkbox
        checked={value}
        onChange={handleContactClick}
        // when disabled color primary
        sx={value ? { padding: 0, cursor: "default", color: blue[500] } : { padding: 0, cursor: "pointer" }}
        //color={value ? "success" : "default"}
        disabled={value}
      />
    );
  }