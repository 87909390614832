import create from "zustand";
import to from "await-to-js";
import axios from "axios";
import useBookingStore from "./bookings";
import { Config } from "../../../config";

interface IHeaders {
  headers: {
    "Content-Type": string;
    Authorization: string;
  }
}

interface State {
  API_PROD_URL: string;
  getConfig: () => IHeaders;
  bookingRevised: (selectedBooking: any) => Promise<boolean>;
  hasTrackingNumber: (selectedBooking: any) => boolean;
  hasQontoId: (selectedBooking: any) => boolean;
}

const { setSnackBar } = useBookingStore.getState();

const useActionsStore = create<State>((set, get) => ({
  API_PROD_URL: Config.REACT_APP_API_TICKETDOOR,
  getConfig() {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ticketdoorToken")}`,
      }
    };
  },
  bookingRevised: async (selectedBooking) => {
    const { hasTrackingNumber, hasQontoId } = get();

    const bookingHandlerId = selectedBooking.booking_handler.id;
    const purchaseProviderId = selectedBooking.booking_handler.purchases_providers[0].id;
    const payment_status = selectedBooking.payment.status;

    console.log("revised TESTING");

    if (payment_status !== "Refund") {
      console.log("not refund");
      const has_tracking_number = hasTrackingNumber(selectedBooking);
      if (!has_tracking_number) {
        console.log("no tracking number");
        setSnackBar({
          open: true,
          message: "Tracking number is required",
          severity: "error",
        });
        setTimeout(() => {
          setSnackBar({
            open: false,
            message: "Tracking number is required",
            severity: "error",
          });
        }
          , 2000);
        return false;
      }

      const has_qonto_id = hasQontoId(selectedBooking);
      console.log("has_qonto_id", has_qonto_id);
      if (!has_qonto_id) {
        console.log("no qonto id");
        setSnackBar({
          open: true,
          message: "Qonto ID is required",
          severity: "error",
        });
        setTimeout(() => {
          setSnackBar({
            open: false,
            message: "Qonto ID is required",
            severity: "error",
          });
        }
          , 2000);
        return false;
      }
    }

    const url = `${get().API_PROD_URL}/booking-handler/revised`;
    const body = {
      by: "CS",
      booking_handler_id: bookingHandlerId,
      purchase_provider_id: purchaseProviderId
    }

    const [err, response] = await to(axios.post(url, body, get().getConfig()));
    if (err) {
        setSnackBar({
          open: true,
          message: "Revised error",
          severity: "error",
        });
        setTimeout(() => {
          setSnackBar({
            open: false,
            message: "Revised error",
            severity: "error",
          });
        }
        , 2000);  
      return false
    };
    console.log("response", response);    
    return true;
  },
  hasTrackingNumber: (selectedBooking) => {
    const { booking_handler } = selectedBooking;
    const { purchases_providers } = booking_handler;
    const { tracking_number } = purchases_providers[0];
    return tracking_number !== null;
  },
  hasQontoId: (selectedBooking) => {
    const { booking_handler } = selectedBooking;
    const { purchases_providers } = booking_handler;
    const { payment_provider } = purchases_providers[0];
    return payment_provider.payment_id !== null;
  },
}));

export default useActionsStore;