import { Button, Collapse, Grid, IconButton, TextField } from "@mui/material";
import { MouseEventHandler, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

// @ts-ignore
import * as locales from "react-date-range/dist/locale";
import moment from "moment";
import useOrdersStore from "../hooks/useOrders";
import { Clear, Search } from "@mui/icons-material";
import { IProject } from "../interfaces/IProject";

interface Props {
  project: IProject | "alerts";
  enviroment: "customers" | "reports" | "alerts";
  showDate?: boolean;
}

const Filters = (props: Props) => {
  const {
    getReport,
    filters,
    setFilters,
    globalSearch,
    getAlerts,
    globalFilter,
    setGlobalFilter,
  } = useOrdersStore((state) => ({
    getReport: state.getReport,
    filters: state.filters,
    setFilters: state.setFilters,
    globalSearch: state.globalSearch,
    getAlerts: state.getAlerts,
    globalFilter: state.globalFilter,
    setGlobalFilter: state.setGlobalFilter,
  }));
  const [open, setOpen] = useState(false);
  const [showDate, setShowDate] = useState(props.showDate ?? false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const renderStrDate = (date: Date) => moment(date).format("DD/MM/YYYY");

  useEffect(() => {
    if (!open) return;
    setState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  }, [open]);

  const searchHandler: MouseEventHandler<HTMLButtonElement> = async () => {
    setGlobalFilter("");
    if (props.enviroment === "reports")
      getReport(
        props.project as IProject,
        moment(state[0].startDate).startOf("day").toISOString(),
        moment(state[0].endDate).endOf("day").toISOString()
      );
    if (props.enviroment === "customers")
      setFilters(
        props.project as IProject,
        filters.status,
        moment(state[0].startDate).startOf("day").toDate(),
        moment(state[0].endDate).endOf("day").toDate()
      );
    if (props.enviroment === "alerts")
      getAlerts(
        moment(state[0].startDate).startOf("day").toISOString(),
        moment(state[0].endDate).endOf("day").toISOString()
      );
    // if (criteriaValue !== "") {
    //   const isValid = await loadOrder(
    //     props.project,
    //     criteriaField,
    //     criteriaValue
    //   );
    //   if (!isValid) navigate("/");
    // }
    setOpen(false);
  };

  const globalSearchHandler = () => {
    globalSearch(props.project as IProject);
  };

  return (
    <Grid item>
      {
        // if showDate is true, show a button with the end and start date
        showDate ? (
          <Button
            variant="outlined"
            size="large"
            onClick={() => setOpen(!open)}
            sx={{ mr: 1 }}
            title="Select a date range"
          >
            {`${renderStrDate(state[0].startDate)} - ${renderStrDate(
              state[0].endDate
            )}`}
          </Button>
        )
          :
          (
            <Button
              variant="outlined"
              size="small"
              onClick={() => setOpen(!open)}
              sx={{ mr: 1 }}
            >
              Filters
            </Button>
          )
      }
      <Collapse
        in={open}
        sx={{ border: "1px solid lightgrey", marginTop: "10px" }}
      >
        <Grid container sx={{ m: 2, mb: 1, mt: 1 }}>
          <Grid item xs={9} alignItems="center" sx={{ m: "auto" }}>
            <h4>Dates</h4>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              size="small"
              // disabled={criteriaValue !== ""}
              onClick={searchHandler}
            >
              Find Many
            </Button>
          </Grid>
        </Grid>
        <DateRangePicker
          onChange={(item: any) => {
            setState([item.selection]);
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          weekStartsOn={1}
          locale={locales.enGB}
          maxDate={new Date()}
          ranges={state}
          direction="horizontal"
        />
      </Collapse>
      {props.enviroment === "customers" && (
        <Grid container sx={{ margin: "10px 0px 10px" }}>
          <Grid container item xs={12}>
            {/* <Grid item xs={9} alignItems="center" sx={{ m: "auto" }}>
              <h4>Filter By</h4>
            </Grid> */}
            {/* <Grid item xs={3}>
              <Button
                variant="outlined"
                size="small"
                disabled={!criteriaValue}
                onClick={searchHandler}
              >
                Find One
              </Button>
            </Grid> */}
          </Grid>
          <Grid container item xs={12} sx={{ alignItems: "end" }}>
            <Grid item xs={4}>
              <TextField
                margin="none"
                label="Search..."
                variant="standard"
                sx={{ width: "100%" }}
                value={globalFilter}
                InputProps={{
                  endAdornment: (
                    <IconButton size="small">
                      <Clear
                        sx={{ visibility: globalFilter ? "visible" : "hidden" }}
                        onClick={() => setGlobalFilter("")}
                      />
                    </IconButton>
                  ),
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  setGlobalFilter(value);
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <IconButton
                color="primary"
                aria-label="search"
                size="small"
                disabled={!globalFilter}
                onClick={(e) => globalSearchHandler()}
                sx={{ border: "1px solid", ml: 2 }}
              >
                <Search />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Filters;
