import useUserPermissions, {
  UserPermissions,
} from "./hooks/useUserPermissions";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({
  children,
  permission,
}: {
  children: any;
  permission: keyof UserPermissions;
}) => {
  const userPermissions = useUserPermissions();

  if (!userPermissions) {
    return <Navigate to="/" />;
  }
  const permissions = userPermissions.permissions;
  if (!permissions || permissions[permission] !== true) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default ProtectedRoute;
