import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import useModalsStore from "../../store/modals";
import useActionsStore from "../../store/actions";
import useBookingStore from "../../store/bookings";
import { useNavigate } from "react-router-dom";

const RevisedDialog = () => {
  const navigate = useNavigate();
  const {
    setSnackBar,
    selectedBooking,
    sendTickets,
    getFileManagement
  } = useBookingStore((state) => ({
    setSnackBar: state.setSnackBar,
    selectedBooking: state.selectedBooking,
    sendTickets: state.sendTickets,
    getFileManagement: state.getFileManagement,
  }));

  const {
    openRevisedDialog,
    setOpenRevisedDialog,
  } = useModalsStore((state) => ({
    openRevisedDialog: state.openRevisedDialog,
    setOpenRevisedDialog: state.setOpenRevisedDialog,
  }));

  const {
    bookingRevised
  } = useActionsStore((state) => ({
    bookingRevised: state.bookingRevised,
  }));

  const handleClose = () => {
    setOpenRevisedDialog(false);
  }

  const handleRevised = async () => {
    const isDone = await bookingRevised(selectedBooking);
    if (isDone) {
      setSnackBar({
        open: true,
        message: "Revised done successfully",
        type: "success",
      });
      await new Promise(r => setTimeout(r, 2000));
      const fileManagement = await getFileManagement(selectedBooking.booking_handler.purchases_providers[0].id);
      if (fileManagement && !fileManagement.has_tickets) {
        const done = await sendTickets("bookingDetails");
        if (done) {
          setSnackBar({
            open: true,
            message: "Send tickets request done",
            type: "success",
          });
        }
      }
      setTimeout(() => {
        handleClose();
        setSnackBar({
          open: false,
          message: "Revised done successfully",
          type: "success",
        });
        navigate("/pendings");
      }
        , 1000);
    }
  }

  return (
    <Dialog
      fullWidth
      open={openRevisedDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Revised
      </DialogTitle>
      <DialogContent>
        Are you sure?
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleRevised} autoFocus>
          Revised
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RevisedDialog;