export const blockAccess = () => {
    var username = localStorage.getItem("username") as string;
    var blockAccess = false;
    var blockedUsers = ["gcastillo", "j.one.jo.stew", "nasdak4ç"];

    if (blockedUsers.includes(username)) {
        console.log("User is blocked: " + username);
        console.log("pathname", window.location.pathname);
        if (window.location.pathname !== "/overview") {
            blockAccess = true;
            window.location.href = "/overview";
        }
    }

    return blockAccess;
}