import { Close, CloseOutlined, UploadFile } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { Cross } from "recharts";
import useBookingStore from "../../store/bookings";

const FileInput = () => {

  const {
    uploadFiles,
    setUploadFiles,
    fileTypeToUpload
  } = useBookingStore((state) => ({
    uploadFiles: state.uploadFiles,
    setUploadFiles: state.setUploadFiles,
    fileTypeToUpload: state.fileTypeToUpload
  }));

  const [fileList, setFileList] = useState([] as string[]);

  const fileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("fileHandler");
    if (!e.target.files) return;
    const files = e.target.files;
    setUploadFiles(Array.from(files));
    const names = Array.from(files).map(file => file.name);
    setFileList(names);
  };

  const handlerRemoveFile = (indexToRemove: number) => {
    const newList = fileList.filter((item, index) => index !== indexToRemove);
    setFileList(newList);

    const newUploadFiles = uploadFiles.filter((file: any, index: number) => index !== indexToRemove);
    setUploadFiles(newUploadFiles);
  }

  return (
    <Grid container alignItems="center" justifyContent="center" margin="0 auto">
      <Box display="block" component="span" sx={{ border: '1px dashed grey', width: '100%', borderRadius: '10px' }}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          style={{ color: "gray", display: "flex", justifyContent: "center", gap: "1rem", alignItems: "center", width: "100%", height: "100%", padding: "1rem" }}
          sx={{ ":hover": { backgroundColor: "transparent" } }}
        >
          {fileTypeToUpload === "tickets" ? (
            <input hidden accept=".pdf" type="file" onChange={fileHandler} multiple />
          ) : (
            <input hidden accept=".pdf" type="file" onChange={fileHandler} />
          )}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" style={{ width: "4rem" }}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <Typography fontSize="16px" variant="caption">Click here to upload your file</Typography>
        </IconButton>
      </Box>
      <Grid style={{ marginTop: "1rem", width: "100%" }}>
        {fileList.length >= 1 &&
          <Grid item style={{ overflow: "hidden" }}>
            {fileList.map((name, index) => {
              return (
                <Grid key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "1rem", marginBottom: "0.5rem", border: "1px solid grey", padding: "1rem", borderRadius: "10px" }}>
                  <Grid style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                    <UploadFile color="action" />
                    {name}
                  </Grid>
                  <CloseOutlined sx={{ cursor: "pointer" }} color="action" onClick={() => {
                    handlerRemoveFile(index)
                  }} />
                </Grid>
              );
            })}
          </Grid>
        }
      </Grid>
    </Grid>
  );
};

export default FileInput;