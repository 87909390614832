import { Box, Grid, MenuItem, TextField } from "@mui/material";
import useBookingStore from "../store/bookings";
import Vars from "../_config/vars";
import useHelpersStore from "../../../hooks/useHelpers";
import { useState } from "react";

const BookingDetailsRender = () => {
  const {
    selectedBooking,
    setSnackBar
  } = useBookingStore((state) => ({
    selectedBooking: state.selectedBooking,
    setSnackBar: state.setSnackBar,
  }));

  const [edit, setEdit] = useState(false);

  const { customer } = selectedBooking;
  const { cart } = selectedBooking;
  const { activity, options } = cart.items[0];
  const { items } = cart;
  const { purchases_providers } = selectedBooking?.booking_handler || [];

  let timeRange = "";
  var entryTimeObject = options.find(
    (option: any) => option.name === "EntryTime"
  );
  if (entryTimeObject) {
    timeRange = entryTimeObject.value;
  }

  const { formatUrl, renderValue, parseToSpaceCase, } = useHelpersStore((state) => ({
    formatUrl: state.formatUrl,
    renderValue: state.renderValue,
    parseToSpaceCase: state.camelCaseToSpaceCase,
  }));

  const bookingDetails: any = {
    bookingId: selectedBooking.id,
    activityName: activity.name,
    statusPurchaseProvider: purchases_providers ? purchases_providers[0].status : "-",
    firstName: customer.first_name,
    purchaseEmail: purchases_providers ? purchases_providers[0].purchase_mail : "-",
    phone: customer.phone,
    attempts: purchases_providers ? purchases_providers[0].attempts : "-",
    timeRange: timeRange || "-",
    paymentId: purchases_providers ? purchases_providers[0].payment_provider.payment_id : "-", 
    trackingNumber: purchases_providers ? purchases_providers[0].tracking_number : "-",
    totalPrice: cart.total_price,
    venueName: items[0].venue.name,
    errorTag: purchases_providers ? purchases_providers[0].error_tag : "-",
    paymentProvider: purchases_providers ? purchases_providers[0].payment_provider.provider : "-",
    sessionId: cart.session_id,
    // only the 4 last digits of the card
    card: purchases_providers ? "************" + purchases_providers[0].payment_provider.credit_card.number_card.slice(-4) : "-",
  }

  const openSnackHandler = (e: any) => {    
    navigator.clipboard.writeText(e.target.value)
    setSnackBar({
      open: true,
      message: "Copied to clipboard",
      severity: "info",
    });

    setTimeout(() => {
      setSnackBar({
        open: false,
        message: "Copied to clipboard",
        severity: "info",
      });
    }, 2000);
  }

  const bookingDetailsFields = Object.keys(bookingDetails).sort((prev, next) => {
    const iPrev = Vars.booking.sorted.indexOf(prev);
    const iNext = Vars.booking.sorted.indexOf(next);
    if (iPrev < iNext) return -1;
    if (iPrev > iNext) return 1;
    return 0;
  }).map((key, i) => {
    return (
      (bookingDetails[key]) ? (
        <Grid key={key}
          onClick={openSnackHandler}
        >
          <TextField
            sx={{                  
              '& .MuiInputBase-root::before': {
                content: Vars.booking.editable.includes(key) && edit ? '""' : "none",
              },
              '& .MuiInputBase-root::after': {
                content: Vars.booking.editable.includes(key) && edit ? '""' : "none",
              },
              '& .MuiInput-input': {
                cursor: "pointer",
              },
              width: "70%",
              marginBottom: 2,
            }}
            InputProps={{
              readOnly: Vars.booking.editable.includes(key) && edit ? false : true,
            }} 
            label={parseToSpaceCase(key)}
            key={key}
            name={key}
            value={bookingDetails[key]}
            variant="standard"
            size="small"
          />
        </Grid>
      ) : (
        <Box key={key}>
          <TextField
            sx={{ width: "70%", marginBottom: 2 }}
            label={parseToSpaceCase(key)}
            key={key}
            name={key}
            value={"-"}
            variant="standard"
            size="small"
            disabled
          />
        </Box>
      )
    )
  });

  return (
    <Grid sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
      {bookingDetailsFields}
    </Grid>
  );
}

export default BookingDetailsRender