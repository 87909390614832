import { useState } from "react";
import useSupportStore from "../store/support";
import useBookingStore from "../store/bookings";
import { useSnackBar } from "./useSnackBar";
import { useUdpatePurchaseProvider } from "./useUpdatePurchaseProvider";

export const usePostComment = () => {
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const { showSnackBar } = useSnackBar();
    const { API_URL } = useSupportStore();
    const { selectedBooking } = useBookingStore();
    const { updatePurchaseProvider } = useUdpatePurchaseProvider();

    const postComment = async (dataProps: {comment: string, statusProp?: string, purchaseProviderIdProp?: string, type?: string}) => {        
        setLoading(true);
        const { comment, statusProp, purchaseProviderIdProp, type } = dataProps;

        var status = statusProp || selectedBooking?.booking_handler?.purchases_providers[0]?.status;
        let purchaseProviderId = purchaseProviderIdProp || selectedBooking?.booking_handler?.purchases_providers[0]?.id;

        if (!purchaseProviderId || !status) {
            if (!purchaseProviderId || !status) {
                //setErrorMsg("Error posting comment");
                showSnackBar("Error posting comment", "error");
                return;
            }
        }

        try {
            const response = await fetch(`${API_URL}/log`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('ticketdoorToken')}`
                },
                body: JSON.stringify({
                    message: comment,
                    by: localStorage.getItem('username'),
                    status,          
                    type,       
                    purchaseProviderId
                })
            })
            const data = await response.json();
            console.log('data log:', data);
            if (data.statusCode === 400) {
                //setErrorMsg("Error posting comment");
                showSnackBar("Error posting comment", "error");
            }

            await updatePurchaseProvider(purchaseProviderId);

            showSnackBar("Comment posted successfully", "success");
            setLoading(false);
        } catch (error) {
            //setErrorMsg("Error posting comment");
            showSnackBar("Error posting comment", "error");
            setLoading(false);
        }
    }

    return { loading, postComment, errorMsg };
};