import moment from "moment";
import { IGridReport } from "../_interfaces/IGridReport";
import { IRowReport } from "../_interfaces/IRowReport";
import { ordersByHourBase } from "./templates/ordersHour";

type IChartType = "netMargin" | "ordersByHour" | "errorsRefunds";

class Graph {
  private _type: IChartType;
  private _report: IGridReport;
  private _APIData: IRowReport;
  private readonly _base = ordersByHourBase;
  constructor(type: IChartType, report: IGridReport, APIData: IRowReport) {
    this._type = type;
    this._report = report;
    this._APIData = APIData;
  }

  writeDataGraph() {
    switch (this._type) {
      case "ordersByHour":
        return this.ordersByHour();
      case "netMargin":
        return this.netMargin();
      case "errorsRefunds":
        return this.errorRefunds();
      default:
        break;
    }
  }

  private ordersByHour() {
    const keys = Object.keys(this._report.ordersByHour);
    const newData = keys.map((key) => ({
      name: key === "23" ? 0 : +key + 1,
      perc: +(
        (this._report.ordersByHour[key] * 100) /
        this._report.totalOrders
      ).toFixed(2),
    }));
    let map: { [x: string]: any } = {};
    this._base.forEach((item) => (map[+item.name + 1] = item));
    newData.forEach((item) => (map[+item.name + 1] = item));
    return Object.values(map).map((row) => ({
      ...row,
      name: row.name + "h",
    }));
  }

  private netMargin() {
    return this._APIData.stats.map((stat) => {
      const m = moment(stat.statsDateISO);
      return {
        name: m.format("DD/MM"),
        value: stat.netMargin,
      };
    });
  }

  private errorRefunds() {
    return this._APIData.stats.map((stat) => {
      const m = moment(stat.statsDateISO);
      console.log("stat", stat);
      return {
        name: m.format("DD/MM"),
        value: stat.errorsRefunds,
      };
    });
  }
}

export default Graph;
