import create from "zustand";
import { BookingRow } from "../_config/customerService.interface";
import { Config } from "../../../config";

interface State {
  API_URL: string;
  bookingsSupport: BookingRow[];
  setBookingsSupport: (bookingsSupport: BookingRow[]) => void;  
}

const useSupportStore = create<State>((set, get) => ({
  API_URL: Config.REACT_APP_API_TICKETDOOR,
  bookingsSupport: [],
  setBookingsSupport: (bookingsSupport: BookingRow[]) => set({ bookingsSupport })
}));

export default useSupportStore;

