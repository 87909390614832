import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  Chip,
  Collapse,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { formatter } from "../../utils/currencyFormatter";
import { DateRange } from "react-date-range";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

// @ts-ignore
import * as locales from "react-date-range/dist/locale";
import { useEffect, useState } from "react";
import moment from "moment";
import useHomeStore from "./_config/useHome";
import { Download, Search } from "@mui/icons-material";
import { IGridReport } from "./_interfaces/IGridReport";
import { OneBarChart } from "../../components/Charts/OneBarChart";
import { ordersByHourBase } from "./_config/templates/ordersHour";
import { OneLineChart } from "../../components/Charts/OneLineChart";
import { OneLineChartOnly } from "../../components/Charts/OneLineChartOnly";
import { useNavigate } from "react-router-dom";
import { blockAccess } from "../../utils/blockAccess";

const renderSelectProject = (item: any, i: number) => {
  return (
    <MenuItem key={i} value={item.value}>
      {item.text}
    </MenuItem>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [state, setState] = useState([
    {
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    Store.isLogged().then((isLogged) => {
      if (!isLogged) return navigate("/");
      const mMin = moment(state[0].startDate).startOf("day").toISOString();
      const mMax = moment(state[0].endDate).endOf("day").toISOString();
      Store.getReport(mMin, mMax);
      blockAccess();
      Store.getQontoBalance();
    });
  }, []);

  const renderStrDate = (date: Date) => moment(date).format("DD/MM/YYYY");

  const reduceStat = (key: keyof IGridReport) =>
    +Store.report.reduce((prev, next) => prev + +next[key], 0).toFixed(2);

  const Store = useHomeStore();

  useEffect(() => {
    console.log(Store.charts);
    const minISO = moment(state[0].startDate).startOf("day").toISOString();
    const maxISO = moment(state[0].endDate).endOf("day").toISOString();
    Store.getReportErrors(minISO, maxISO);
    //renderGridRowsErrors();
  }, [Store.charts]);

  const exportHandler = () => {
    const minISO = moment(state[0].startDate).startOf("day").toISOString();
    const maxISO = moment(state[0].endDate).endOf("day").toISOString();
    Store.reportAsCSV(minISO, maxISO);
  };

  const exportGeneralHandler = () => {
    const minISO = moment(state[0].startDate).startOf("day").toISOString();
    const maxISO = moment(state[0].endDate).endOf("day").toISOString();
    Store.reportAsCSV(minISO, maxISO);
  };

  const submitHandler = () => {
    setOpenCollapse(false);
    const mMin = moment(state[0].startDate).startOf("day").toISOString();
    const mMax = moment(state[0].endDate).endOf("day").toISOString();
    Store.getReport(mMin, mMax);
  };

  const renderPrimaryMainCards = () => {
    const cardInfo = [
      { title: "Qonto Balance", value: Store.qontoBalance ?? NaN },
      { title: "Total orders", value: reduceStat("totalOrders") },
      {
        title: "Net Margin",
        value: reduceStat("netMargin"),
        label: +(
          (reduceStat("netMargin") * 100) /
          reduceStat("grossIncomes")
        ).toFixed(2),
      },
      {
        title: "Avg. CPA",
        value: +(reduceStat("ppc") / reduceStat("totalOrders")).toFixed(2),
      },
      {
        title: "Margen medio por orden",
        value: +(reduceStat("netMargin") / reduceStat("totalOrders")).toFixed(
          2
        ),
      },
    ];

    return cardInfo.map((card, i) => {
      return (
        <Grid xs={12 / 5} key={i}>
          <Paper elevation={1}>
            <Grid container spacing={0} sx={{ p: 2 }}>
              <Grid className="paper-title" xs={12}>
                {card.title}
              </Grid>
              <Grid className="paper-value" xs={9}>
                {/* @ts-ignore */}
                {[0, 2].includes(i) ? formatter.format(card.value) : card.value}
              </Grid>
              <Grid xs={3} alignSelf="center">
                {card.label && <Chip label={`${card.label}%`} />}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    });
  };

  const renderGridRowsErrors = () => {
    const reportErrors = Store.reportErrors[0];
    console.log("reportErrors", reportErrors);
    const totalRefunds =
      reportErrors?.totalOnHoldRefunds + reportErrors?.totalToReviewRefunds;
    const jsx = (
      <TableBody>
        <TableRow>
          <TableCell
            style={{ color: "red", fontWeight: "bold", padding: "4px" }}
          >
            On Holds
          </TableCell>
          <TableCell style={{ padding: "4px", fontWeight: "bold" }}>
            {reportErrors?.onHold}
          </TableCell>
          <TableCell style={{ padding: "4px", fontWeight: "bold" }}>
            {((reportErrors?.onHold / reportErrors?.totalErrors) * 100).toFixed(
              2
            )}
            %
          </TableCell>
          <TableCell style={{ padding: "4px", fontWeight: "bold" }}>
            {(
              (reportErrors?.totalOnHoldRefunds / reportErrors?.onHold) *
              100
            ).toFixed(2)}
            %
          </TableCell>
          <TableCell style={{ padding: "4px", fontWeight: "bold" }}>
            {reportErrors?.totalOnHoldRefunds}
          </TableCell>
        </TableRow>
        {reportErrors?.onHoldErrors["ErrorRetry again×"] && (
          <TableRow>
            <TableCell
              style={{
                paddingLeft: "2rem",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "4px",
              }}
            >
              Error Try Again
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.onHoldErrors["ErrorRetry again×"]?.total}
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.onHoldErrors["ErrorRetry again×"]?.total /
                  reportErrors?.onHold) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.onHoldErrors["ErrorRetry again×"]?.refunds /
                  reportErrors?.onHoldErrors["ErrorRetry again×"]?.total) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.onHoldErrors["ErrorRetry again×"]?.refunds}
            </TableCell>
          </TableRow>
        )}
        {reportErrors?.onHoldErrors["TimeoutError: waiting for selector"] && (
          <TableRow>
            <TableCell
              style={{
                paddingLeft: "2rem",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "4px",
              }}
            >
              TimeoutError
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {
                reportErrors?.onHoldErrors["TimeoutError: waiting for selector"]
                  ?.total
              }
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.onHoldErrors[
                  "TimeoutError: waiting for selector"
                ]?.total /
                  reportErrors?.onHold) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.onHoldErrors[
                  "TimeoutError: waiting for selector"
                ]?.refunds /
                  reportErrors?.onHoldErrors[
                    "TimeoutError: waiting for selector"
                  ]?.total) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {
                reportErrors?.onHoldErrors["TimeoutError: waiting for selector"]
                  ?.refunds
              }
            </TableCell>
          </TableRow>
        )}
        {reportErrors?.onHoldErrors["is block card"] && (
          <TableRow>
            <TableCell
              style={{
                paddingLeft: "2rem",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "4px",
              }}
            >
              Block Card
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.onHoldErrors["is block card"]?.total}
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.onHoldErrors["is block card"]?.total /
                  reportErrors?.onHold) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.onHoldErrors["is block card"]?.refunds /
                  reportErrors?.onHoldErrors["is block card"]?.total) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.onHoldErrors["is block card"]?.refunds}
            </TableCell>
          </TableRow>
        )}
        {reportErrors?.onHoldErrors["New Error url"] && (
          <TableRow>
            <TableCell
              style={{
                paddingLeft: "2rem",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "4px",
              }}
            >
              New Error url
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.onHoldErrors["New Error url"]?.total}
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.onHoldErrors["New Error url"]?.total /
                  reportErrors?.onHold) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.onHoldErrors["New Error url"]?.refunds /
                  reportErrors?.onHoldErrors["New Error url"]?.total) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.onHoldErrors["New Error url"]?.refunds}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell
            style={{ color: "blue", fontWeight: "bold", padding: "4px" }}
          >
            To Review
          </TableCell>
          <TableCell style={{ padding: "4px", fontWeight: "bold" }}>
            {reportErrors?.toReview}
          </TableCell>
          <TableCell style={{ padding: "4px", fontWeight: "bold" }}>
            {(
              (reportErrors?.toReview / reportErrors?.totalErrors) *
              100
            ).toFixed(2)}
            %
          </TableCell>
          <TableCell style={{ padding: "4px", fontWeight: "bold" }}>
            {(
              (reportErrors?.totalToReviewRefunds / reportErrors?.toReview) *
              100
            ).toFixed(2)}
            %
          </TableCell>
          <TableCell style={{ padding: "4px", fontWeight: "bold" }}>
            {reportErrors?.totalToReviewRefunds}
          </TableCell>
        </TableRow>
        {reportErrors?.toReviewErrors["Unavailable Time"] && (
          <TableRow>
            <TableCell
              style={{
                paddingLeft: "2rem",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "4px",
              }}
            >
              Unavailable Time
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.toReviewErrors["Unavailable Time"]?.total}
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.toReviewErrors["Unavailable Time"]?.total /
                  reportErrors?.toReview) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.toReviewErrors["Unavailable Time"]?.refunds /
                  reportErrors?.toReviewErrors["Unavailable Time"]?.total) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.toReviewErrors["Unavailable Time"]?.refunds}
            </TableCell>
          </TableRow>
        )}
        {reportErrors?.toReviewErrors["Await mail"] && (
          <TableRow>
            <TableCell
              style={{
                paddingLeft: "2rem",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "4px",
              }}
            >
              Await mail
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.toReviewErrors["Await mail"]?.total}
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.toReviewErrors["Await mail"]?.total /
                  reportErrors?.toReview) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.toReviewErrors["Await mail"]?.refunds /
                  reportErrors?.toReviewErrors["Await mail"]?.total) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.toReviewErrors["Await mail"]?.refunds}
            </TableCell>
          </TableRow>
        )}
        {reportErrors?.toReviewErrors["New Error url"] && (
          <TableRow>
            <TableCell
              style={{
                paddingLeft: "2rem",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "4px",
              }}
            >
              New Error url
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.toReviewErrors["New Error url"]?.total}
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.toReviewErrors["New Error url"]?.total /
                  reportErrors?.toReview) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.toReviewErrors["New Error url"]?.refunds /
                  reportErrors?.toReviewErrors["New Error url"]?.total) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.toReviewErrors["New Error url"]?.refunds}
            </TableCell>
          </TableRow>
        )}
        {reportErrors?.toReviewErrors["ErrorRetry again×"] && (
          <TableRow>
            <TableCell
              style={{
                paddingLeft: "2rem",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingRight: "4px",
              }}
            >
              Error Try Again
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.toReviewErrors["ErrorRetry again×"]?.total}
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.toReviewErrors["ErrorRetry again×"]?.total /
                  reportErrors?.toReview) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {(
                (reportErrors?.toReviewErrors["ErrorRetry again×"]?.refunds /
                  reportErrors?.toReviewErrors["ErrorRetry again×"]?.total) *
                100
              ).toFixed(2)}
              %
            </TableCell>
            <TableCell style={{ padding: "4px" }}>
              {reportErrors?.toReviewErrors["ErrorRetry again×"]?.refunds}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell style={{ fontWeight: "bold", padding: "4px" }}>
            Total Errores
          </TableCell>
          <TableCell style={{ fontWeight: "bold", padding: "4px" }}>
            {reportErrors?.totalErrors}
          </TableCell>
          <TableCell style={{ fontWeight: "bold", padding: "4px" }}>
            {(
              (reportErrors?.totalErrors / reportErrors?.totalOrders) *
              100
            ).toFixed(2)}
            %
          </TableCell>
          <TableCell style={{ fontWeight: "bold", padding: "4px" }}>
            {((totalRefunds / reportErrors?.totalErrors) * 100).toFixed(2)}%
          </TableCell>
          <TableCell style={{ fontWeight: "bold", padding: "4px" }}>
            {totalRefunds}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ fontWeight: "bold", padding: "4px" }}>
            Total Orders
          </TableCell>
          <TableCell style={{ fontWeight: "bold", padding: "4px" }}>
            {reportErrors?.totalOrders}
          </TableCell>
        </TableRow>
      </TableBody>
    );

    return jsx;
  };

  const renderGridRows = () => {
    const jsx = Store.report.map((row, i) => (
      <TableRow key={i}>
        <TableCell>{row.project}</TableCell>
        <TableCell>{row.totalOrders}</TableCell>
        <TableCell>{row.totalTickets}</TableCell>
        <TableCell>{row.sales}</TableCell>
        <TableCell>{row.refundsCount}</TableCell>
        <TableCell>{row.refundsCost}</TableCell>
        <TableCell>{row.cost}</TableCell>
        <TableCell>{row.grossIncomes}</TableCell>
        <TableCell>{row.netIncomes}</TableCell>
        <TableCell>{row.ppc}</TableCell>
        <TableCell>{row.commisions}</TableCell>
        <TableCell>{row.cpa}</TableCell>

        <TableCell>{row.netMargin}</TableCell>
      </TableRow>
    ));
    jsx.push(
      <TableRow key={"total"}>
        <TableCell className="grid-summary">{"Total"}</TableCell>
        <TableCell className="grid-summary">
          {reduceStat("totalOrders")}
        </TableCell>
        <TableCell className="grid-summary">
          {reduceStat("totalTickets")}
        </TableCell>
        <TableCell className="grid-summary">{reduceStat("sales")}</TableCell>
        <TableCell className="grid-summary">
          {reduceStat("refundsCount")}
        </TableCell>
        <TableCell className="grid-summary">
          {reduceStat("refundsCost")}
        </TableCell>
        <TableCell className="grid-summary">{reduceStat("cost")}</TableCell>
        <TableCell className="grid-summary">
          {reduceStat("grossIncomes")}
        </TableCell>
        <TableCell className="grid-summary">
          {reduceStat("netIncomes")}
        </TableCell>
        <TableCell className="grid-summary">{reduceStat("ppc")}</TableCell>
        <TableCell className="grid-summary">
          {reduceStat("commisions")}
        </TableCell>
        <TableCell className="grid-summary">
          {(reduceStat("ppc") / reduceStat("totalOrders")).toFixed(2)}
        </TableCell>

        <TableCell className="grid-summary">
          {reduceStat("netMargin")}
        </TableCell>
      </TableRow>
    );
    return jsx;
  };

  return (
    <Grid id="main-home" container sx={{ p: 4 }}>
      <Grid container xs={12} columnGap={2}>
        <Grid container xs={12} alignItems="center">
          <Grid>
            <TextField
              label="Select Date"
              variant="outlined"
              value={`${renderStrDate(state[0].startDate)} - ${renderStrDate(
                state[0].endDate
              )}`}
              sx={{ width: 264 }}
              onClick={() => setOpenCollapse(true)}
            ></TextField>
          </Grid>
          <Grid>
            <IconButton
              color="primary"
              aria-label="search"
              size="small"
              onClick={submitHandler}
              sx={{ border: "1px solid", ml: 2 }}
            >
              <Search />
            </IconButton>
          </Grid>
          <Grid>
            {/* <IconButton
              color="primary"
              aria-label="download"
              size="small"
              onClick={submitHandler}
              sx={{ border: "1px solid", ml: 2 }}
            >
              <Download />
            </IconButton> */}
            <Button
              color="primary"
              aria-label="download-variants"
              size="small"
              variant="outlined"
              startIcon={<Download />}
              sx={{ border: "1px solid", ml: 2 }}
              onClick={exportHandler}
            >
              Variants
            </Button>
          </Grid>
          <Grid>
            <Button
              color="primary"
              aria-label="download-variants"
              size="small"
              variant="outlined"
              startIcon={<Download />}
              sx={{ border: "1px solid", ml: 2 }}
              onClick={exportGeneralHandler}
            >
              General
            </Button>
          </Grid>
        </Grid>
        <Grid sx={{ maxWidth: 335 }}>
          <Collapse
            in={openCollapse}
            sx={{ border: "1px solid lightgrey", marginTop: "5px" }}
          >
            <DateRange
              onChange={(item: any) => {
                setState([item.selection]);
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              weekStartsOn={1}
              locale={locales.enGB}
              maxDate={new Date()}
              ranges={state}
              direction="horizontal"
            />
          </Collapse>
        </Grid>
      </Grid>
      <Grid container spacing={2} xs={12} marginTop="1rem">
        {renderPrimaryMainCards()}
      </Grid>
      <TableContainer component={Grid} xs={12} marginTop="42px">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell>Total Orders</TableCell>
              <TableCell>Total Tickets</TableCell>
              <TableCell>Sales</TableCell>
              <TableCell>Refunds</TableCell>
              <TableCell>Refunds (€)</TableCell>
              <TableCell>Cost</TableCell>
              <TableCell>Net Sales</TableCell>
              <TableCell>Free Tax Net Sales</TableCell>
              <TableCell>PPC</TableCell>
              <TableCell>Commisions</TableCell>
              <TableCell>CPA</TableCell>
              <TableCell>Net Margin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderGridRows()}</TableBody>
        </Table>
      </TableContainer>
      <Grid xs={2} marginTop="42px">
        <TextField
          select
          label="Project"
          value={Store.chartsProj}
          onChange={({ target }) => Store.setChartsProj(target.value)}
          fullWidth
        >
          {Store.report
            .map((row) => ({ value: row.project, text: row.project }))
            .map(renderSelectProject)}
        </TextField>
      </Grid>
      <Grid xs={12} container marginTop="42px" justifyContent="space-around">
        <Grid xs={6}>
          <OneBarChart data={Store.charts.ordersByHour} />
        </Grid>
        <Grid xs={6}>
          <OneLineChart data={Store.charts.netMargin} />
        </Grid>
      </Grid>
      <h1 style={{ marginTop: "42px" }}>Error reporting</h1>
      <Grid xs={12} container marginTop="24px">
        <Grid xs={6}>
          <TableContainer component={Grid} xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ERROR</TableCell>
                  <TableCell>Volume</TableCell>
                  <TableCell style={{ textAlign: "center" }}>%</TableCell>
                  <TableCell>% Refund</TableCell>
                  <TableCell>Total Refunds</TableCell>
                </TableRow>
              </TableHead>
              {renderGridRowsErrors()}
            </Table>
          </TableContainer>
        </Grid>
        {/* <Grid xs={6}>
          <Grid display="flex" justifyContent="space-between">
            <p>Error & refund</p>
            <span>0,95%</span>
          </Grid>
          <OneLineChartOnly data={Store.charts.errorsRefunds} />
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Home;
