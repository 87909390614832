import { Config } from "./../../../config";
import to from "await-to-js";
import axios from "axios";
import create from "zustand";
import { IGridReport } from "../_interfaces/IGridReport";
import { IRowReport } from "../_interfaces/IRowReport";
import { IStats } from "../_interfaces/IStats";
import Graph from "./Graph";
import { ordersByHourBase } from "./templates/ordersHour";
import { SheetsJS } from "../../../utils/xlsx";

type IChartType = "netMargin" | "ordersByHour" | "errorsRefunds";

interface ICharts {
  netMargin: any;
  ordersByHour: any;
  errorsRefunds: any;
}

interface IErrors {
  [key: string]: {
    total: number;
    refunds: number;
  };
}

export interface IErrorReport {
  totalOrders: number;
  totalErrors: number;
  onHold: number;
  toReview: number;
  onHoldErrors: IErrors;
  toReviewErrors: IErrors;
  totalOnHoldRefunds: number;
  totalToReviewRefunds: number;
  // onHoldRetryAgain: {
  //   total: number;
  //   refunds: number;
  // };
  // onHoldBlockCard: {
  //   total: number;
  //   refunds: number;
  // };
  // toReviewUnavailableTime: {
  //   total: number;
  //   refunds: number;
  // },
  // toReviewAwaitMail: {
  //   total: number;
  //   refunds: number;
  // }
}

interface HomeStatus {
  apiData: any[];
  report: IGridReport[];
  getReport: (minISO: string, maxISO: string) => void;
  chartsProj: string;
  setChartsProj: (newProj: string) => void;
  initGraphs: () => void;
  setChart: (chartType: IChartType) => void;
  charts: ICharts;
  qontoBalance: number;
  getQontoBalance: () => void;
  reportErrors: IErrorReport[];
  getReportErrors: (minISO: string, maxISO: string) => void;
  isLogged: () => Promise<boolean>;
  //getOnHolds: () => void;
  reportAsCSV: (minISO: string, maxISO: string) => void;
}

const URLS = {
  SAFA: Config.REACT_APP_API_SAGRADA,
  COLO: Config.REACT_APP_API_COLOSSEUM,
  PAGU: Config.REACT_APP_API_PARC_GUELL,
  VAMU: Config.REACT_APP_API_VATICAN,
  VEPA: Config.REACT_APP_API_VERSAILLES,
};

const Vars = {
  SAFA: "sagrada",
  COLO: "colosseum",
  PAGU: "parkguell",
  VAMU: "vatican",
  VEPA: "versailles",
};

type VarKeys = keyof typeof Vars;

export interface IOrder {
  ticketsDate: string;
  timeRange: string;
  status: string;
  totalPrice: number;
  [x: string]: any;
}

interface Log {
  status: string;
  accion: string;
}

const useHomeStore = create<HomeStatus>((set, get) => {
  return {
    apiData: [],
    report: [],
    async getReport(minISO, maxISO) {
      const [err, response] = await to(
        axios.get<IRowReport[]>(
          `https://rprtng.crlanjdrktity.com/reports?minDate=${minISO}&maxDate=${maxISO}`
        )
      );
      if (err) return;
      console.log(response.data);
      const gridReport = response.data.map((row) => {
        const { stats } = row;
        const reduceStat = (key: keyof IStats) =>
          +stats.reduce((prev, next) => prev + +next[key], 0).toFixed(2);

        function sumObjectsByKey(...objs: any[]) {
          return objs.reduce((a: any, b: any) => {
            for (let k in b) {
              if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
            }
            return a;
          }, {});
        }

        const arrHours = stats
          .map((stat) => stat.ordersByHour)
          .reduce((p, n) => {
            const sum = sumObjectsByKey(p, n);
            return sum;
          }, {});

        return {
          project: row.project,
          totalOrders: reduceStat("totalOrders"),
          totalTickets: reduceStat("totalTickets"),
          sales: reduceStat("sales"),
          cost: reduceStat("cost"),
          grossIncomes: reduceStat("grossIncomes"),
          refundsCount: reduceStat("refundsCounter"),
          refundsCost: reduceStat("refundsCost"),
          commisions: reduceStat("commisions"),
          netIncomes: reduceStat("netIncomes"),
          ppc: reduceStat("ppc"),
          cpa: +(reduceStat("ppc") / reduceStat("totalOrders")).toFixed(2),
          netMargin: reduceStat("netMargin"),
          ordersByHour: arrHours,
        };
      });
      console.log("gridReport", gridReport);
      set(() => ({ report: gridReport }));
      set(() => ({ apiData: response.data }));
      get().initGraphs();
    },
    reportErrors: [],
    async getReportErrors(minISO, maxISO) {
      const query = `minDate=${minISO}` + `&maxDate=${maxISO}`;
      const projectKey = get().chartsProj as keyof typeof URLS;
      console.log("projectKey", projectKey);

      const url = URLS[projectKey];
      console.log("url", `${url}/api/orders?${query}`);
      const key: VarKeys = projectKey;
      const project = Vars[key];
      console.log("project", project);

      const [err, response] = await to(
        axios.get<IOrder[]>(`${url}/api/orders?${query}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(`${project}Token`)}`,
          },
        })
      );
      if (err) return false;
      //console.log("response", response.data);

      // reduce orders if have logs with status "on hold"
      const onHold = response.data.reduce((acc, order) => {
        const logs = order.logs as Log[];
        const onHold = logs.find((log) => log.status === "on hold");
        if (onHold) {
          acc.push(order);
        }
        return acc;
      }, [] as IOrder[]);

      // reduce orders if have logs with status "to review"
      const toReview = response.data.reduce((acc, order) => {
        const logs = order.logs as Log[];
        const toReview = logs.find((log) => log.status === "to review");
        if (toReview) {
          acc.push(order);
        }
        return acc;
      }, [] as IOrder[]);

      console.log("onHold", onHold);
      console.log("toReview", toReview);

      const totalOrders = response.data.length;
      const totalErrors = onHold.length + toReview.length;

      // reduce onHold orders that have logs with accion "Error"
      const onHoldErrors = onHold.reduce((acc, order) => {
        const logs = order.logs as Log[];
        const error = logs.find(
          (log) => log.status === "on hold" && log.accion.includes("Error")
        );
        if (error) {
          acc.push(order);
        }
        return acc;
      }, [] as IOrder[]);

      // make key value pair of onHoldErrors like this { "Error 1": { total: 2, refunds: 1 }}
      const onHoldErrorsObj = onHoldErrors.reduce((acc, order) => {
        const logs = order.logs as Log[];
        const logFind = logs.find(
          (log) =>
            (log.status === "on hold" && log.accion.includes("Error")) ||
            log.accion.includes("block")
        );
        if (logFind) {
          let logFindErrorName: string = logFind.accion;
          if (logFindErrorName.includes("waiting for selector"))
            logFindErrorName = "TimeoutError: waiting for selector";
          if (logFindErrorName.includes("New Error url"))
            logFindErrorName = "New Error url";
          if (acc[logFindErrorName]) {
            acc[logFindErrorName].total += 1;
            if (order.status === "refund") {
              acc[logFindErrorName].refunds += 1;
            }
          } else {
            acc[logFindErrorName] = { total: 1, refunds: 0 };
            if (order.status === "refund") {
              acc[logFindErrorName].refunds += 1;
            }
          }
        }
        return acc;
      }, {} as { [key: string]: { total: number; refunds: number } });

      //console.log("onHoldErrorsObj", onHoldErrorsObj);

      const toReviewErrors = toReview.reduce((acc, order) => {
        const logs = order.logs as Log[];
        const error = logs.find(
          (log) => log.status === "to review" && log.accion.includes("Error")
        );
        if (error) {
          acc.push(order);
        }
        return acc;
      }, [] as IOrder[]);

      console.log("toReviewErrors", toReviewErrors);

      const toReviewErrorsObj = toReviewErrors.reduce((acc, order) => {
        const logs = order.logs as Log[];
        const logFind = logs.find(
          (log) =>
            (log.status === "to review" && log.accion.includes("Error")) ||
            log.accion.includes("Unavailable")
        );

        if (logFind) {
          let logFindErrorName = logFind.accion;
          if (logFindErrorName.includes("New Error url"))
            logFindErrorName = "New Error url";
          if (logFindErrorName.includes("Unavailable"))
            logFindErrorName = "Unavailable Time";
          if (logFindErrorName.includes("Cannot get"))
            logFindErrorName = "Await mail";
          if (acc[logFindErrorName]) {
            acc[logFindErrorName].total += 1;
            if (order.status === "refund") {
              acc[logFindErrorName].refunds += 1;
            }
          } else {
            acc[logFindErrorName] = { total: 1, refunds: 0 };
            if (order.status === "refund") {
              acc[logFindErrorName].refunds += 1;
            }
          }
        }
        return acc;
      }, {} as { [key: string]: { total: number; refunds: number } });

      console.log("toReviewErrorsObj", toReviewErrorsObj);
      const totalOnHoldRefunds = Object.values(onHoldErrorsObj).reduce(
        (acc, error) => {
          acc += error.refunds;
          return acc;
        },
        0
      );

      const totalToReviewRefunds = Object.values(toReviewErrorsObj).reduce(
        (acc, error) => {
          acc += error.refunds;
          return acc;
        },
        0
      );

      const errorReporData = {
        totalOrders,
        totalErrors,
        onHold: onHold.length,
        toReview: toReview.length,
        onHoldErrors: onHoldErrorsObj,
        toReviewErrors: toReviewErrorsObj,
        totalOnHoldRefunds,
        totalToReviewRefunds,
      };

      set(() => ({ reportErrors: [errorReporData] }));
    },
    chartsProj: "PAGU",
    setChartsProj(newProj) {
      set(() => ({ chartsProj: newProj }));
      get().initGraphs();
    },
    initGraphs() {
      get().setChart("ordersByHour");
      get().setChart("netMargin");
      get().setChart("errorsRefunds");
    },
    setChart(chartType) {
      const report = get().report.find(
        (report) => report.project === get().chartsProj
      );
      const APIData = get().apiData.find(
        (row) => row.project === get().chartsProj
      );
      if (!report || !APIData) return ordersByHourBase;
      const graph = new Graph(chartType, report, APIData);
      set((state) => ({
        charts: { ...state.charts, [chartType]: graph.writeDataGraph() },
      }));
    },
    charts: {
      netMargin: ordersByHourBase,
      ordersByHour: ordersByHourBase,
      errorsRefunds: ordersByHourBase,
    },
    qontoBalance: 0,
    async getQontoBalance() {
      const [err, response] = await to(
        axios.get<{ err: boolean; data: number }>(
          `https://rprtng.crlanjdrktity.com/qonto/current-balance`
        )
      );
      if (err) return;
      if (response.data.err) return;
      set(() => ({ qontoBalance: response.data.data }));
    },

    async isLogged() {
      let isLogged = true;
      const ticketdoorToken = localStorage.getItem("ticketdoorToken");
      // const parkguellToken = localStorage.getItem("parkguellToken");

      //TODO: TEMPORAL
      return ticketdoorToken ? true : false;

      const projects = [
        {
          token: ticketdoorToken,
          url: Config.REACT_APP_API_TICKETDOOR,
        },
        // {
        //   token: parkguellToken,
        //   url: Config.REACT_APP_API_PARC_GUELL,
        // },
      ];
      for (const project of projects) {
        try {
          const response = await axios.get(
            `${project.url}/api/orders/global?value=test`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${project.token}`,
              },
            }
          );
          if (response.status !== 200) isLogged = false;
        } catch (error) {
          isLogged = false;
        }
      }
      return isLogged;
    },

    async reportAsCSV(minISO, maxISO) {
      const response = await axios.get(
        `https://rprtng.crlanjdrktity.com/reports/format/variants?minDate=${minISO}&maxDate=${maxISO}`
      );
      console.log("TEST ", response.data);

      const data = response.data;
      const sheets = new SheetsJS(data);
      sheets.test2();
    },

    async reportCSVGeneral() {
      const reponse = await axios.get(
        `https://rprtng.crlanjdrktity.com/reports/format/general`
      );
      console.log("TEST ", reponse.data);

      const data = reponse.data;
      const sheets = new SheetsJS(data);
      sheets.test2();
    },
  };
});

export default useHomeStore;
