import { useState } from "react";
import useBookingStore from "../store/bookings";

export function useGetBooking() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { API_PROD_URL } = useBookingStore();
  const getBooking = async (id: string) => {
    setLoading(true);
    try {
      const response = await fetch(`${API_PROD_URL}/bookings/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('ticketdoorToken')}`
        }
      });
      const booking = await response.json();
      setLoading(false);
      return booking;
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setError(true);
    }    
  }
  return { getBooking, loading, error };
}